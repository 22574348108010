import "./styles.css";

import React, { useState } from "react";
import { useParams } from "react-router-dom";

import PropTypes from "prop-types";

import {
  Box,
  Typography,
  Paper,
  Avatar,
  IconButton,
  Grid,
  NativeSelect,
  FormControl,
  InputBase,
  Menu,
  Button,
  MenuItem,
} from "@material-ui/core";
import shuffle from "lodash/shuffle";
import {
  Link as LinkIcon,
  FormatQuote as QuoteIcon,
  ArrowDropDown,
} from "@material-ui/icons";
import { Slug, Fade } from "mauerwerk";
import Masonry from "react-masonry-css";

import Card from "@material-ui/core/Card";

import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { quotes } from "./data";

quotes.forEach((quote, index) => {
  quote["key"] = index;
});

const images = {
  Taeyong: require("../../assets/images/taeyong.jpg"),
  Johnny: require("../../assets/images/johnny.jpeg"),
  Ten: require("../../assets/images/ten.jpg"),
  Renjun: require("../../assets/images/renjun.jpg"),
};

const test = [
  require("../../assets/images/taeyong-1.jpeg"),
  require("../../assets/images/taeyong-2.jpeg"),
  require("../../assets/images/taeyong-3.jpg"),
  require("../../assets/images/taeyong-4.jpeg"),
  require("../../assets/images/taeyong-5.jpeg"),
  require("../../assets/images/taeyong-6.jpg"),
  require("../../assets/images/taeyong-7.jpeg"),
  require("../../assets/images/taeyong-8.jpg"),
  require("../../assets/images/taeyong-9.jpeg"),
];

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
    justifyContent: "center",
    alignItems: "center",
    whiteSpace: "nowrap",
  },

  grid: {
    // marginRight: theme.spacing(4),
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  card: {
    borderRadius: theme.spacing(1),
    borderWidth: 1,
    background: `linear-gradient(135deg,${theme.primaryColor.import[300]},${theme.primaryColor.import[800]})`,
    padding: theme.spacing(2),
    // marginLeft: theme.spacing(4),
    color: "black",
    // margin: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    // textAlign: "left",
    // // position: "absolute",
    // bottom: "0px",
    // left: "0px",
    // width: "100%",
    // height: "100%",
  },
  button: {
    borderRadius: theme.spacing(1),
    borderWidth: 1,
    background: `linear-gradient(135deg,${
      theme.primaryColor.import[400]
    },${"#ffffff"})`,
    padding: theme.spacing(2),
    marginBottom: "30px",
    color: "black",
    // margin: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    // textAlign: "left",
    // // position: "absolute",
    // bottom: "0px",
    // left: "0px",
    // width: "100%",
    // height: "100%",
  },
  quoteText: { color: "white" },
  nameText: { color: "white" },

  avatar: {
    // margin: "auto",
    // marginBottom: theme.spacing(2),
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  input: {
    color: theme.primaryColor.import[800],
    // "@media (min-width:600px)": {
    //   fontSize: "1.5rem",
    // },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.4rem",
    },
    fontWeight: 700,
    display: "flex",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
  },
}));

function isEven(n) {
  return n % 2 == 0;
}

function isOdd(n) {
  return Math.abs(n % 2) == 1;
}

const QuoteCard = ({ quote, index }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} m={2}>
      <Box
        style={{
          transform: "scaleX(-1)",
          alignSelf: "flex-start",
        }}
      >
        <QuoteIcon className={classes.quoteText} fontSize="large" />
      </Box>
      <Box p={2}>
        <Typography
          variant="h6"
          className={classes.quoteText}
          style={{
            whiteSpace: "pre-line",
            fontWeight: 500,
            textAlign: "left",
          }}
          // color="textSecondary"
        >
          {quote.quote} <br />
        </Typography>
      </Box>
      <Box
        style={{
          alignSelf: "flex-end",
        }}
      >
        <QuoteIcon className={classes.quoteText} fontSize="large" />
      </Box>
      <Box m={2}>
        <Typography
          variant="subtitle1"
          color="primary"
          className={classes.nameText}
          style={{ fontWeight: 500, fontStyle: "italic" }}
        >
          {quote.member}
        </Typography>
      </Box>

      {/* <Box
        mt={2}
        style={{
          display: "flex",
          alignSelf: "flex-start",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box m={1} />
        <Typography
          variant="body"
          color="textSecondary"
          
          style={{ fontWeight: 500, fontStyle="italic" }}
        >
          {quote.member}
     
        </Typography>
      </Box> */}
    </Card>
  );
};

const Header = ({ filter, setFilter, initialValue }) => {
  const filters = ["motivation", "hope", "kindness", "advice", "everything"];
  const classes = useStyles();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [highlighted, setHighlighted] = useState(initialValue);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const FilterItem = (value) => (
    <MenuItem
      onClick={() => {
        setFilter(value);
        handleClose();
      }}
      onMouseOver={() => setHighlighted(value)}
      style={
        highlighted === value
          ? {
              background: `linear-gradient(135deg,${theme.primaryColor.import[300]},${theme.primaryColor.import[800]})`,
              color: "white",
            }
          : undefined
      }
    >
      <Typography
        variant="h6"
        color="textSecondary"
        style={{ fontWeight: 500 }}
      >
        {value}
      </Typography>
    </MenuItem>
  );

  return (
    <Box mt={3} mb={3} textAlign="center" className={classes.header}>
      <Box width={{ xs: "100%", md: "auto" }} p={{ xs: 0, md: 2 }}>
        <Typography variant="h3">For when you need</Typography>
      </Box>

      <Button
        onClick={handleClick}
        className={classes.input}
        endIcon={<ArrowDropDown fontSize="large" />}
      >
        {filter}
      </Button>
      <Menu
        anchorEl={anchorEl}
        position="absolute"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        // style={{ background: theme.primaryColor.import[100] }}
      >
        {filters.map((f) => FilterItem(f))}
      </Menu>
    </Box>
  );
};
const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 2,
  500: 1,
};

function QuotesScreen(props) {
  const classes = useStyles();
  const { type } = useParams();

  const [filter, setFilter] = useState(type || "motivation");
  const data =
    filter === "everything"
      ? quotes
      : shuffle(
          quotes.filter(
            (quote) => quote.filter && quote.filter.includes(filter)
          )
        );

  return (
    <Box
      position="relative"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        // transform: "translate(-50%, 0%)",
      }}
    >
      <Header
        filter={filter}
        setFilter={setFilter}
        initialValue={type || "motivation"}
      />
      <Box pl="30px" pr="30px">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {data.map((quote, index) => (
            <Fade
              unique
              reset
              from={{ opacity: 0, transform: "translate3d(0,140px,0)" }}
              enter={{ opacity: 1, transform: "translate3d(0,0px,0)" }}
              leave={{ opacity: 0, transform: "translate3d(0,-50px,0)" }}
            >
              {/* {test[index] ? (
              <img
                src={test[index]}
                style={{ width: "100%", height: "auto", borderRadius: "16px" }}
              />
            ) : null} */}
              <QuoteCard quote={quote} key={quote.key} />
            </Fade>
          ))}
        </Masonry>
      </Box>
    </Box>
  );

  return null;
}

QuotesScreen.defaultProps = {
  type: "page",
  size: "medium",
  padding: 2,
};

QuotesScreen.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  padding: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.element,
};

export default QuotesScreen;
