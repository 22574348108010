import React, { useState } from "react";

import PropTypes from "prop-types";

import { Dialog, Box, Typography, Button } from "@material-ui/core";
import { SPACE_GIF } from "../../assets";

function NeoZone(props) {
  const [open, setOpen] = useState(true);
  const close = () => setOpen(false);
  const [loaded, setLoaded] = useState(false);
  return (
    <Dialog open={open} fullScreen>
      <Box>
        <Box width="100%" height="100%">
          <img
            src={SPACE_GIF.src}
            style={{
              height: "100%",
              width: "100%",
              maxHeight: "100%",
              objectFit: "cover",
              position: "absolute",
            }}
            alt="Neo Zone"
            onLoad={() => setLoaded(true)}
          />

          <Box
            p={{ xs: 4, md: 6, lg: 12, xl: 16 }}
            style={{
              position: "absolute",
              color: "white",
              top: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
              flexDirection: "column",
              background: loaded ? "transparent" : "black",
            }}
          >
            <Typography
              variant="h4"
              style={{
                color: "white",
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              You are entering <br /> NEO ZONE.
              <br />
              <br />
              In this space, nothing matters except for you and the work you
              consider important.
              <br />
              <br />
              Give your complete focus.
              <br />
              <br />
            </Typography>

            <Button
              variant="outlined"
              size="large"
              style={{
                color: "white",
                borderColor: "white",
                borderWidth: "6px",
                fontSize: "1.5rem",
                fontWeight: 700,
              }}
              onClick={close}
            >
              let's go
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

export default NeoZone;
