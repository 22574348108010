export const audioFiles = [
  {
    file: require("./elevator-ding.mp3"),
    title: "Elevator Ding",
    key: "elevator-ding",
  },
  {
    file: require("./chenle-finish-this.mp3"),
    title: "Chenle - Let's finish this",
    key: "chenle-finish-this",
  },
  {
    file: require("./lucas-neomu-happy.mp3"),
    title: "Lucas - Neomu happy",
    key: "lucas-neomu-happy",
  },
  {
    file: require("./time-over.mp3"),
    title: "Taeyong & Johnny - Time Over",
    key: "time-over",
  },
  {
    file: require("./jungwoo-asap.mp3"),
    title: "Jungwoo - ASAP",
    key: "jungwoo-asap",
  },
  {
    file: require("./winwin-winwin.mp3"),
    title: "Winwin - let's Winwin!",
    key: "winwin-winwin",
  },
  {
    file: require("./mark-shine.mp3"),
    title: "Mark - Time to shine!",
    key: "mark-shine",
  },
  {
    file: require("./jaehyun-get-it.mp3"),
    title: "Jaehyun - Let's get it!",
    key: "jaehyun-get-it",
  },
];

const audioPlayers = {};
audioFiles.forEach((sound) => {
  var audio = new Audio();
  audio.src = sound.file;
  audioPlayers[sound.key] = {
    audio: audio,
    title: sound.title,
  };
});

export default audioPlayers;

console.log("audioPlayers", audioPlayers);
