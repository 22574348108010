import React from "react";
import PropTypes from "prop-types";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tooltip,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Box,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { Close as CloseIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

function AboutDialog(props) {
  const classes = useStyles();
  const dialogProps = props.dialogProps;
  const user = props.user;
  const version = process.env.REACT_APP_VERSION;

  if (!user && !version) {
    return null;
  }

  return (
    <Dialog fullWidth maxWidth="sm" {...dialogProps}>
      <DialogTitle disableTypography>
        <Typography variant="h6">About NeoPom</Typography>

        <Tooltip title="Close">
          <IconButton
            className={classes.closeButton}
            onClick={dialogProps.onClose}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <DialogContent>
        <List disablePadding>
          <ListItem>
            <ListItemText
              primary="What is it?"
              secondary="A gamified productivity app for fans of the K-pop group, NCT"
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary="Attribution"
              secondary="This app wouldn't have been possible without the resources below"
            />
          </ListItem>

          <ListItem>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="right">Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <a target="_blank" href="https://github.com/rmuif">
                        RMUIF
                      </a>
                    </TableCell>
                    <TableCell align="right">
                      Supercharged version of Create React App with all the
                      bells and whistles.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <a target="_blank" href="https://undraw.co">
                        unDraw
                      </a>
                    </TableCell>
                    <TableCell align="right">
                      A constantly updated design project with beautiful SVG
                      images.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <a target="_blank" href="https://giphy.com/">
                        Giphy
                      </a>
                    </TableCell>
                    <TableCell align="right">
                      Credit to{" "}
                      <a
                        target="_blank"
                        href="https://giphy.com/ericaofanderson"
                      >
                        Erica Anderson
                      </a>{" "}
                      for her incredible gif (used in NeoZone)
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <a
                        target="_blank"
                        href="https://www.youtube.com/channel/UCtj_K6W16mmBzqBcbxGcvYA"
                      >
                        Limitless TY
                      </a>
                    </TableCell>
                    <TableCell align="right">
                      Youtube Channel for inspirational Taeyong messages
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <a target="_blank" href="https://naepageo.blogspot.com/">
                        Naepageo
                      </a>
                    </TableCell>
                    <TableCell align="right">
                      Blog for NCT motivational quotes
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Box m={1} />
      </DialogActions>
    </Dialog>
  );
}

AboutDialog.propTypes = {
  dialogProps: PropTypes.object.isRequired,
  user: PropTypes.object,
};

export default AboutDialog;
