import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSpring, animated as a } from "react-spring";
import { Link } from "react-router-dom";
import sample from "lodash/sample";
import moment from "moment";
import firebase, { firestore } from "../../firebase";
import useFirestoreQuery from "../../utils/useFirestoreQuery";
import Loader from "../Loader";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tooltip,
  IconButton,
  Button,
  Box,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { Close as CloseIcon } from "@material-ui/icons";

import photocard from "./utils";
import { QUESTION_IMG, GIFT_IMG } from "../../assets";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  content: {
    height: 300,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    margin: theme.spacing(2),
  },
  image: {
    height: 300,
    width: 225,
    resizeMode: "cover",
    borderRadius: theme.spacing(2),
    position: "absolute",
    top: 0,
  },
  gift: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    height: 300,
    width: 300,
    resizeMode: "cover",
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    padding: theme.spacing(2),
    textAlign: "center",
  },
  message: {
    whiteSpace: "pre-line",
  },
}));

const defaultTimerData = {
  focusTime: 25,
  shortBreakTime: 5,
  longBreakTime: 30,
  sessionAudio: "elevator-ding",
  breakAudio: "elevator-ding",
  volume: 100,
  dailyGoal: 8,
  stretchGoal: 12,
};

function RandomSelection(props) {
  const [revealed, setRevealed] = useState(false);
  const { opacity, transform } = useSpring({
    opacity: revealed ? 1 : 0,
    transform: `perspective(600px) rotateY(${revealed ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });
  const classes = useStyles();
  let selectedPC;
  const { userId } = props;
  const { data, status, _error } = useFirestoreQuery(
    firestore.collection("users").doc(userId)
  );

  const timer = data
    ? { ...defaultTimerData, ...data.timer }
    : defaultTimerData;
  const TODAY = timer.dayStartTime
    ? moment().subtract(timer.dayStartTime, "hours").format("YYYY-MM-DD")
    : moment().format("YYYY-MM-DD");

  const dailyData = data && data.history && data.history[TODAY];
  const pc1 =
    dailyData && data.history[TODAY].photocard
      ? data.history[TODAY].photocard
      : undefined;
  const pc2 =
    dailyData && data.history[TODAY].second_photocard
      ? data.history[TODAY].second_photocard
      : undefined;
  const dailySessions =
    dailyData && data.history[TODAY].sessionTotal
      ? data.history[TODAY].sessionTotal
      : 0;
  const title = "Claim your second photocard!";

  const message = `Wow, you really challenged yourself today.\n
    Here's your second photocard as a reward!`;

  if (status === "loading") {
    return <Loader />;
  }

  if (dailySessions < timer.stretchGoal) return null;

  if (!data || !pc1) {
    return null;
  }

  // Early exit if user received a photocard for the day
  if (pc1 && pc2) {
    return (
      <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
        <DialogTitle disableTypography>
          <Typography variant="h6">Here are today's photocards!</Typography>

          <Tooltip title="Close">
            <IconButton className={classes.closeButton} onClick={props.onClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          <Box className={classes.content}>
            <a.img className={classes.image} src={pc2.path} />
          </Box>
          <Box className={classes.content}>
            <a.img className={classes.image} src={pc1.path} />
          </Box>
        </DialogContent>
        <DialogActions textAlign="center">
          <Box className={classes.actions}>
            <Typography variant="subtitle2" className={classes.message}>
              {pc2.member === pc1.member
                ? `You got double ${pc2.member}!`
                : `You got ${pc2.member} and ${pc1.member}!`}
            </Typography>
            <br />
            <Button
              component={Link}
              to={"/photocards"}
              variant="contained"
              size="small"
              color="primary"
            >
              See collection
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    );
  }

  // Logic for randomly selecting and revealing a photocard
  const handleReveal = () => {
    let choices;
    if (data && data.photocards) {
      // Limit choices to photos that user doesn't have
      const ownedPhotoKeys = data.photocards.map(p => p.key);
      choices = photocard.photocards.filter(
        (pc) => !ownedPhotoKeys.includes(pc.key)
      );
    } else {
      choices = photocard.photocards;
    }
    selectedPC = sample(choices);

    firestore
      .collection("users")
      .doc(userId)
      .update({
        photocards: firebase.firestore.FieldValue.arrayUnion(selectedPC),
        [`history.${TODAY}.second_photocard`]: selectedPC,
      });

    setRevealed(true);
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <DialogTitle disableTypography>
        <Typography variant="h6">{title}</Typography>

        <Tooltip title="Close">
          <IconButton className={classes.closeButton} onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <DialogContent>
        <Box className={classes.content}>
          <a.img
            className={classes.image}
            src={pc2 ? pc2.path : QUESTION_IMG.src}
            style={{
              opacity,
              transform: transform.interpolate((t) => `${t} rotateY(180deg)`),
            }}
          />
          <a.img
            src={GIFT_IMG.src}
            alt="Gift"
            style={{ opacity: opacity.interpolate((o) => 1 - o), transform }}
            className={classes.gift}
          />
        </Box>
        <Box className={classes.content}>
          <a.img className={classes.image} src={pc1.path} />
        </Box>
      </DialogContent>
      <DialogActions textAlign="center">
        <Box className={classes.actions}>
          <Typography variant="subtitle2" className={classes.message}>
            {revealed
              ? `You got ${
                  pc2 ? `${pc2.member}! for your 2nd photocard!` : "..."
                }`
              : message}
          </Typography>
          <br />
          {revealed ? (
            <Button
              component={Link}
              to={"/photocards"}
              variant="contained"
              size="small"
              color="primary"
              onClick={props.onClose}
            >
              See collection
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={handleReveal}
            >
              Click to reveal
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
}

RandomSelection.propTypes = {
  user: PropTypes.object,
};

export default RandomSelection;
