import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close as CloseIcon, Info as InfoIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

function InfoSection(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleOpen} aria-label="info">
        <InfoIcon fontSize="small" />
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle disableTypography>
          <Typography variant="h6">Overview</Typography>

          <Tooltip title="Close">
            <IconButton className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          This is where you can see all your photocards!
          <br />
          Member photocards are added to NCT and their respective sub-units. For
          example, a Winwin photocard will count for NCT, NCT 127, and WayV.
          Therefore, adding photocards by sub-unit may result in a number larger
          than the actual total.
          <br />
          <br />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default InfoSection;
