import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSpring, animated as a } from "react-spring";
import firebase, { firestore } from "../../firebase";
import useFirestoreQuery from "../../utils/useFirestoreQuery";
import Loader from "../Loader";

import {
  Typography,
  Tooltip,
  IconButton,
  Button,
  Box,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { VolumeOff, VolumeUp, VolumeDown } from "@material-ui/icons";
import audioPlayers from "../../assets/sounds";
const useStyles = makeStyles((theme) => ({
  message: {
    whiteSpace: "pre-line",
  },
}));

function SoundControl({ userId }) {
  const { data, status, _error } = useFirestoreQuery(
    firestore.collection("users").doc(userId || "nctzen")
  );
  const [volume, setVolume] = useState(
    (data && data.timer && data.timer.volume) || 100
  );
  return (
    <Box>
      <Tooltip title="You can further customize by going to the Timer tab under Settings">
        <IconButton
          onClick={() => {
            if (volume === 0) {
              setVolume(100);
              audioPlayers[
                (data && data.timer && data.timer.sessionAudio) ||
                  "elevator-ding"
              ].audio.volume = 1;

              audioPlayers[
                (data && data.timer && data.timer.breakAudio) || "elevator-ding"
              ].audio.volume = 1;
              firestore
                .collection("users")
                .doc(userId)
                .update({
                  [`timer.volume`]: 100,
                });
            } else {
              setVolume(0);
              audioPlayers[
                (data && data.timer && data.timer.sessionAudio) ||
                  "elevator-ding"
              ].audio.volume = 0;

              audioPlayers[
                (data && data.timer && data.timer.breakAudio) || "elevator-ding"
              ].audio.volume = 0;
              firestore
                .collection("users")
                .doc(userId)
                .update({
                  [`timer.volume`]: 0,
                });
            }
          }}
        >
          {volume === 0 ? <VolumeOff /> : <VolumeUp />}
        </IconButton>
      </Tooltip>
    </Box>
  );
}

SoundControl.propTypes = {
  user: PropTypes.object,
};

export default SoundControl;
