import React, { useState } from "react";

import PropTypes from "prop-types";

import {
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  Tooltip,
  Switch,
  IconButton,
  Select,
  MenuItem,
  Slider,
  Hidden,
  TextField,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core";

import {
  Audiotrack,
  VolumeOff,
  VolumeUp,
  VolumeDown,
  Create as CreateIcon,
  EmojiEvents as TrophyIcon,
  TrendingUp,
  Restore as RestoreIcon,
} from "@material-ui/icons";
import audioPlayers, { audioFiles } from "../../assets/sounds";
import firebase, { firestore } from "../../firebase";

const initialData = {
  focusTime: 25,
  shortBreakTime: 5,
  longBreakTime: 30,
  sessionAudio: "elevator-ding",
  breakAudio: "elevator-ding",
  volume: 100,
  dailyGoal: 8,
  stretchGoal: 12,
  dayStartTime: 0,
};

const initialErrors = {
  focusTime: "",
  shortBreakTime: "",
  longBreakTime: "",
  dailyGoal: "",
  stretchGoal: "",
};

const defaultRanges = {
  focusTime: { min: 5, max: 240 },
  shortBreakTime: { min: 1, max: 60 },
  longBreakTime: { min: 1, max: 120 },
  dailyGoal: { min: 3, max: 100 },
};

function TimerTab(props) {
  const { userData, user } = props;
  const timer = { ...initialData, ...userData.timer };
  const [showingField, setShowingField] = useState(null);
  // Using state for volume because it is updated by a slider
  // Don't want to update the database until change is committed
  const [volume, setVolume] = useState(timer.volume);

  // For validating user input
  const [errors, setErrors] = useState(initialErrors);
  const ranges = {
    ...defaultRanges,
    stretchGoal: { min: timer.dailyGoal, max: 100 },
  };

  const updateField = (field, value) => {
    if (value === "") {
      setErrors({
        ...errors,
        [field]: `Cannot be empty`,
      });
      return;
    }
    firestore
      .collection("users")
      .doc(user.uid)
      .update({
        [`timer.${field}`]: Number(value),
      });
  };

  const handleChange = (field, value) => {
    if (value === "") {
      errors[field] && setErrors({ ...errors, [field]: "" });
      return;
    } else if (value < ranges[field].min) {
      setErrors({
        ...errors,
        [field]: `This cannot be less than ${ranges[field].min}`,
      });
      return;
    } else if (value > ranges[field].max) {
      setErrors({
        ...errors,
        [field]: `This cannot be greater than ${ranges[field].max}`,
      });
      return;
    } else {
      errors[field] && setErrors({ ...errors, [field]: "" });
    }
  };

  return (
    <DialogContent>
      <List disablePadding>
        <ListItem>
          <Box mb={1}>
            <Typography variant="subtitle1" color="textSecondary">
              Duration (minutes)
            </Typography>
          </Box>
        </ListItem>
        <ListItem>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type="number"
                label="NeoPom Session"
                defaultValue={timer.focusTime}
                variant="outlined"
                error={!!errors.focusTime}
                helperText={errors.focusTime}
                onBlur={(e) => {
                  updateField("focusTime", e.target.value);
                }}
                onChange={(e) => {
                  handleChange("focusTime", e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type="number"
                label="Short Break"
                defaultValue={timer.shortBreakTime}
                error={!!errors.shortBreakTime}
                helperText={errors.shortBreakTime}
                variant="outlined"
                onBlur={(e) => {
                  updateField("shortBreakTime", e.target.value);
                }}
                onChange={(e) => handleChange("shortBreakTime", e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type="number"
                label="Long Break"
                defaultValue={timer.longBreakTime}
                error={!!errors.longBreakTime}
                helperText={errors.longBreakTime}
                variant="outlined"
                onBlur={(e) => {
                  updateField("longBreakTime", e.target.value);
                }}
                onChange={(e) => handleChange("longBreakTime", e.target.value)}
              />
            </Grid>
          </Grid>
        </ListItem>

        <Box m={2} />

        <ListItem>
          <Typography variant="subtitle1" color="textSecondary">
            Goals
          </Typography>
        </ListItem>

        <Hidden xsDown>
          <ListItem>
            <ListItemIcon>
              <TrendingUp />
            </ListItemIcon>

            <ListItemText
              primary="Daily Goal"
              secondary={"Receive a photocard for reaching this goal!"}
            />
            {/* <ListItemSecondaryAction> */}
            <TextField
              style={{ maxWidth: 100 }}
              type="number"
              label="Sessions"
              defaultValue={timer.dailyGoal}
              variant="filled"
              error={!!errors.dailyGoal}
              helperText={errors.dailyGoal}
              onBlur={(e) => {
                updateField("dailyGoal", e.target.value);
              }}
              onChange={(e) => handleChange("dailyGoal", e.target.value)}
            />
            {/* </ListItemSecondaryAction> */}
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <TrophyIcon />
            </ListItemIcon>

            <ListItemText
              primary="Stretch Goal"
              secondary={"Receive a 2nd photocard for challenging yourself"}
            />
            <TextField
              style={{ maxWidth: 100 }}
              type="number"
              label="Sessions"
              defaultValue={timer.stretchGoal}
              error={!!errors.stretchGoal}
              helperText={errors.stretchGoal}
              variant="filled"
              onBlur={(e) => {
                updateField("stretchGoal", e.target.value);
              }}
              onChange={(e) => handleChange("stretchGoal", e.target.value)}
            />
          </ListItem>
        </Hidden>

        <Hidden smUp>
          <ListItem>
            <Box
              display="flex"
              width="100%"
              flexDirection="column"
              textAlign="right"
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <ListItemIcon>
                  <TrendingUp />
                </ListItemIcon>
                <Box flexGrow={1} />

                <ListItemText
                  primary="Daily Goal"
                  secondary={"Receive a photocard for reaching this goal!"}
                />
              </Box>

              <Box display="flex" flexGrow={1} justifyContent="flex-end">
                <TextField
                  fullWidth
                  type="number"
                  label="Sessions"
                  defaultValue={timer.dailyGoal}
                  variant="filled"
                  error={!!errors.dailyGoal}
                  helperText={errors.dailyGoal}
                  onBlur={(e) => {
                    updateField("dailyGoal", e.target.value);
                  }}
                  onChange={(e) => handleChange("dailyGoal", e.target.value)}
                />
              </Box>
            </Box>
          </ListItem>

          <ListItem>
            <Box
              display="flex"
              width="100%"
              flexDirection="column"
              textAlign="right"
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <ListItemIcon>
                  <TrophyIcon />{" "}
                </ListItemIcon>
                <Box flexGrow={1} />
                <ListItemText
                  primary="Stretch Goal"
                  secondary={"Receive a 2nd photocard for pushing yourself"}
                />
              </Box>

              <Box display="flex" flexGrow={1} justifyContent="flex-end">
                <TextField
                  fullWidth
                  type="number"
                  label="Sessions"
                  defaultValue={timer.stretchGoal}
                  variant="filled"
                  error={!!errors.stretchGoal}
                  helperText={errors.stretchGoal}
                  onBlur={(e) => {
                    updateField("stretchGoal", e.target.value);
                  }}
                  onChange={(e) => handleChange("stretchGoal", e.target.value)}
                />
              </Box>
            </Box>
          </ListItem>
        </Hidden>

        <Box m={1} />

        <Box m={2} />

        <ListItem>
          <Typography variant="subtitle1" color="textSecondary">
            Sound
          </Typography>
        </ListItem>

        <Hidden smUp>
          <ListItem>
            <Box
              display="flex"
              width="100%"
              flexDirection="column"
              textAlign="right"
            >
              <Box display="flex" flexDirection="row">
                <ListItemIcon>
                  {volume === 0 ? (
                    <VolumeOff />
                  ) : volume < 50 ? (
                    <VolumeDown />
                  ) : (
                    <VolumeUp />
                  )}
                </ListItemIcon>
                <Box flexGrow={1} />

                <ListItemText primary="Volume" />
              </Box>

              <Box display="flex" flexGrow={1} justifyContent="flex-end">
                <Slider
                  style={{ minWidth: 100 }}
                  value={volume}
                  onChange={(e, newValue) => {
                    setVolume(newValue);
                  }}
                  onChangeCommitted={(e, newValue) => {
                    audioPlayers[timer.sessionAudio].audio.volume =
                      newValue / 100;
                    audioPlayers[timer.breakAudio].audio.volume =
                      newValue / 100;
                    audioPlayers[timer.sessionAudio].audio.play();
                    firestore
                      .collection("users")
                      .doc(user.uid)
                      .update({
                        [`timer.volume`]: newValue,
                      });
                  }}
                />
              </Box>
            </Box>
          </ListItem>

          <ListItem>
            <Box
              display="flex"
              width="100%"
              flexDirection="column"
              textAlign="right"
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <ListItemIcon>
                  <Audiotrack />
                </ListItemIcon>
                <Box flexGrow={1} />

                <ListItemText
                  primary="Session Audio"
                  secondary={"This plays at the end of your sessions"}
                />
              </Box>

              <Box display="flex" flexGrow={1} justifyContent="flex-end">
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  open={showingField === "sessionAudio"}
                  onClose={() => setShowingField(null)}
                  onOpen={() => setShowingField("sessionAudio")}
                  value={timer.sessionAudio}
                  onChange={(e) => {
                    const { value } = e.target;
                    audioPlayers[value].audio.volume = volume / 100;
                    audioPlayers[value].audio.play();
                    firestore
                      .collection("users")
                      .doc(user.uid)
                      .update({
                        [`timer.sessionAudio`]: value,
                      });
                  }}
                >
                  {audioFiles.map((sound) => (
                    <MenuItem value={sound.key}>{sound.title}</MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          </ListItem>

          <ListItem>
            <Box
              display="flex"
              width="100%"
              flexDirection="column"
              textAlign="right"
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <ListItemIcon>
                  <Audiotrack />
                </ListItemIcon>
                <Box flexGrow={1} />
                <ListItemText
                  primary="Break Audio"
                  secondary={"This plays at the end of your breaks"}
                />
              </Box>

              <Box display="flex" flexGrow={1} justifyContent="flex-end">
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  open={showingField === "breakAudio"}
                  onClose={() => setShowingField(null)}
                  onOpen={() => setShowingField("breakAudio")}
                  value={timer.breakAudio}
                  onChange={(e) => {
                    const { value } = e.target;
                    audioPlayers[value].audio.volume = volume / 100;
                    audioPlayers[value].audio.play();
                    firestore
                      .collection("users")
                      .doc(user.uid)
                      .update({
                        [`timer.breakAudio`]: value,
                      });
                  }}
                >
                  {audioFiles.map((sound) => (
                    <MenuItem value={sound.key}>{sound.title}</MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          </ListItem>
        </Hidden>
        <Hidden xsDown>
          <ListItem>
            <ListItemIcon>
              {volume === 0 ? (
                <VolumeOff />
              ) : volume < 50 ? (
                <VolumeDown />
              ) : (
                <VolumeUp />
              )}
            </ListItemIcon>

            <ListItemText primary="Volume" />

            <ListItemSecondaryAction>
              <Slider
                style={{ minWidth: 100 }}
                value={volume}
                onChange={(e, newValue) => {
                  setVolume(newValue);
                }}
                onChangeCommitted={(e, newValue) => {
                  audioPlayers[timer.sessionAudio].audio.volume =
                    newValue / 100;
                  audioPlayers[timer.breakAudio].audio.volume = newValue / 100;
                  audioPlayers[timer.sessionAudio].audio.play();
                  firestore
                    .collection("users")
                    .doc(user.uid)
                    .update({
                      [`timer.volume`]: newValue,
                    });
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <Audiotrack />
            </ListItemIcon>
            <ListItemText
              primary="Session Audio"
              secondary={"This plays at the end of your sessions"}
            />

            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={showingField === "sessionAudio"}
              onClose={() => setShowingField(null)}
              onOpen={() => setShowingField("sessionAudio")}
              value={timer.sessionAudio}
              onChange={(e) => {
                const { value } = e.target;
                audioPlayers[value].audio.volume = volume / 100;
                audioPlayers[value].audio.play();
                firestore
                  .collection("users")
                  .doc(user.uid)
                  .update({
                    [`timer.sessionAudio`]: value,
                  });
              }}
            >
              {audioFiles.map((sound) => (
                <MenuItem value={sound.key}>{sound.title}</MenuItem>
              ))}
            </Select>
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <Audiotrack />
            </ListItemIcon>
            <ListItemText
              primary="Break Audio"
              secondary={"This plays at the end of your breaks"}
            />
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={showingField === "breakAudio"}
              onClose={() => setShowingField(null)}
              onOpen={() => setShowingField("breakAudio")}
              value={timer.breakAudio}
              onChange={(e) => {
                const { value } = e.target;
                audioPlayers[value].audio.volume = volume / 100;
                audioPlayers[value].audio.play();
                firestore
                  .collection("users")
                  .doc(user.uid)
                  .update({
                    [`timer.breakAudio`]: value,
                  });
              }}
            >
              {audioFiles.map((sound) => (
                <MenuItem value={sound.key}>{sound.title}</MenuItem>
              ))}
            </Select>
          </ListItem>
        </Hidden>

        <Box m={2} />

        <ListItem>
          <Typography variant="subtitle1" color="textSecondary">
            Time Cycle
          </Typography>
        </ListItem>

        <Hidden smUp>
          <ListItem>
            <Box
              display="flex"
              width="100%"
              flexDirection="column"
              textAlign="right"
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <ListItemIcon>
                  <RestoreIcon />
                </ListItemIcon>
                <Box flexGrow={1} />
                <ListItemText
                  primary="Start of Day - Time"
                  secondary={"The time your progress resets"}
                />
              </Box>

              <Box display="flex" flexGrow={1} justifyContent="flex-end">
                <Select
                  open={showingField === "dayStartTime"}
                  onClose={() => setShowingField(null)}
                  onOpen={() => setShowingField("dayStartTime")}
                  value={timer.dayStartTime}
                  onChange={(e) => {
                    const { value } = e.target;
                    firestore
                      .collection("users")
                      .doc(user.uid)
                      .update({
                        [`timer.dayStartTime`]: value,
                      });
                  }}
                >
                  <MenuItem value={0}>12 AM</MenuItem>
                  <MenuItem value={1}>1 AM</MenuItem>
                  <MenuItem value={2}>2 AM</MenuItem>
                  <MenuItem value={3}>3 AM</MenuItem>
                  <MenuItem value={4}>4 AM</MenuItem>
                  <MenuItem value={5}>5 AM</MenuItem>
                  <MenuItem value={6}>6 AM</MenuItem>
                  <MenuItem value={7}>7 AM</MenuItem>
                  <MenuItem value={8}>8 AM</MenuItem>
                </Select>
              </Box>
            </Box>
          </ListItem>
        </Hidden>

        <Hidden xsDown>
          <ListItem>
            <ListItemIcon>
              <RestoreIcon />
            </ListItemIcon>
            <ListItemText
              primary="Start of Day - Time"
              secondary={"The time your progress resets"}
            />
            <Select
              open={showingField === "dayStartTime"}
              onClose={() => setShowingField(null)}
              onOpen={() => setShowingField("dayStartTime")}
              value={timer.dayStartTime}
              onChange={(e) => {
                const { value } = e.target;
                firestore
                  .collection("users")
                  .doc(user.uid)
                  .update({
                    [`timer.dayStartTime`]: value,
                  });
              }}
            >
              <MenuItem value={0}>12 AM</MenuItem>
              <MenuItem value={1}>1 AM</MenuItem>
              <MenuItem value={2}>2 AM</MenuItem>
              <MenuItem value={3}>3 AM</MenuItem>
              <MenuItem value={4}>4 AM</MenuItem>
              <MenuItem value={5}>5 AM</MenuItem>
              <MenuItem value={6}>6 AM</MenuItem>
              <MenuItem value={7}>7 AM</MenuItem>
              <MenuItem value={8}>8 AM</MenuItem>
            </Select>
          </ListItem>
        </Hidden>

        <ListItem>
          <Typography variant="body" color="textSecondary">
            <i>This is for people who like to work late into the night.</i>
          </Typography>
        </ListItem>
      </List>
    </DialogContent>
  );
}

TimerTab.propTypes = {
  // Properties
  theme: PropTypes.object.isRequired,

  // Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default TimerTab;
