import React, { useState, useEffect } from "react";
import moment from "moment";

import { useParams, Link } from "react-router-dom";

import {
  Grid,
  Fab,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
  Button,
  Slider,
  Typography,
} from "@material-ui/core";
import useWidth from "../../utils/useWidth";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";

import {
  Refresh as RefreshIcon,
  Home as HomeIcon,
  Add as AddIcon,
  Folder as FolderIcon,
  Delete as DeleteIcon,
  AlarmOn as AlarmIcon,
  PlayArrow as PlayIcon,
  Done as DoneIcon,
  Pause as PauseIcon,
  FiberManualRecord as CircleIcon,
  CheckCircle,
  Archive as ArchiveIcon,
} from "@material-ui/icons";

import firebase, { firestore } from "../../firebase";
import useFirestoreQuery from "../../utils/useFirestoreQuery";

import EmptyState from "../EmptyState";

import Loader from "../Loader";
import UserCard from "../UserCard";
import ProgressHistory from "./Progress";
import NeoZone from "./NeoZone";
import TimerDialog from "../TimerDialog";
import UpdateMessage from "./UpdateMessage";
import RandomSelection from "../Photocards/RandomSelection";
import RandomSelection2 from "../Photocards/RandomSelection2";

import { ReactComponent as ErrorIllustration } from "../../illustrations/error.svg";
import { ReactComponent as NoDataIllustration } from "../../illustrations/no-data.svg";

const useStyles = makeStyles((theme) => ({
  grid: {
    margin: 0,
    width: "100%",
  },
  circleIcon: {
    color: "gray",
  },
  activeTask: {
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  finishedTask: {
    textDecorationLine: "line-through",
    textDecorationStyle: "solid",
  },
  greenAvatar: {
    backgroundColor: theme.primaryColor.import[400],
  },
}));

const userObj = {
  todos: [],
  photocards: [],
  history: [],
};

const fakeUserObj = {
  todos: ["Laundry", "Buy eggs and milk", "Run for 20 minutes"],
  photocards: [],
  history: [
    {
      date: "",
      sessionTotal: 6,
      activeTasks: [],
      finishedTasks: [],
    },
  ],
  dailySession: {
    isActive: false,
    total: 0,
  },
};

const emptyData = {
  sessionTotal: 0,
};

const defaultTimerData = {
  focusTime: 25,
  shortBreakTime: 5,
  longBreakTime: 30,
  sessionAudio: "elevator-ding",
  breakAudio: "elevator-ding",
  volume: 100,
  dailyGoal: 8,
  stretchGoal: 12,
};

const PrettoSlider = withStyles({
  root: {
    color: "#52af77",
    height: 8,
    width: "100%",
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,

    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  markLabel: {
    textAlign: "center",
    top: 40,
    fontSize: "0.6rem",
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  mark: {
    backgroundColor: "#fff",
    height: 8,
    width: 4,
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

function UserPage() {
  const classes = useStyles();
  const theme = useTheme();
  const width = useWidth();
  const smallPage = ["xs", "sm"].includes(width);
  const { userId } = useParams();
  const { data, status, _error } = useFirestoreQuery(
    firestore.collection("users").doc(userId)
  );
  const timerData = data
    ? { ...defaultTimerData, ...data.timer }
    : defaultTimerData;
  const TODAY = timerData.dayStartTime
    ? moment().subtract(timerData.dayStartTime, "hours").format("YYYY-MM-DD")
    : moment().format("YYYY-MM-DD");
  const dailyData =
    data && data.history && data.history[TODAY]
      ? { ...emptyData, ...data.history[TODAY] }
      : emptyData;
  const [taskText, setTaskText] = useState("");
  const [taskError, setTaskError] = useState(null);
  const [rewarded, setRewarded] = useState(false);
  const [rewarded2, setRewarded2] = useState(false); // for second photocard

  const neoPomTotal = dailyData.sessionTotal || 0;
  const progressArray = new Array(8).fill().map((_, i) => i < neoPomTotal);
  const marks = new Array(neoPomTotal).fill().map((_, i) => ({
    value: i + 1,
  }));

  useEffect(() => {
    if (dailyData.sessionTotal >= timerData.dailyGoal && !dailyData.photocard) {
      setRewarded(true);
    }
    if (
      dailyData.sessionTotal >= timerData.stretchGoal &&
      !dailyData.second_photocard
    ) {
      setRewarded2(true);
    }
  }, [data]);

  const addTask = () => {
    if (!taskText.length) {
      setTaskError("Task cannot be blank");
      return;
    }
    setTaskText("");
    firestore
      .collection("users")
      .doc(userId)
      .update({
        todos: firebase.firestore.FieldValue.arrayUnion(taskText),
      });
  };

  function renderMessage() {
    if (dailyData.sessionTotal >= timerData.stretchGoal) {
      return (
        <Grid item xs={12} md={6} textAlign="center">
          <Box textAlign="center">
            <Typography variant="subtitle2" color="textSecondary">
              Wow, you completed {dailyData.sessionTotal} sessions today.
              <br />
              You should be very proud of yourself!
              <br />
              Make sure to rest well. Life is a marathon, not a sprint ~
            </Typography>
            <br />
            <Button onClick={() => setRewarded2(true)} color="primary">
              Click to see your photocards
            </Button>
          </Box>
        </Grid>
      );
    } else if (dailyData.sessionTotal >= timerData.dailyGoal) {
      return (
        <Grid item xs={12} md={6} textAlign="center">
          <Box textAlign="center">
            <Typography variant="subtitle2" color="textSecondary">
              Congrats on completing {dailyData.sessionTotal} sessions today!
              <br />
              You can challenge yourself to {timerData.stretchGoal} sessions for
              a 2nd photocard!
              <br />
              Don't worry if you can't reach it -- you worked hard and should be
              very proud of yourself.
            </Typography>
            <Button onClick={() => setRewarded(true)} color="primary">
              Click to see your photocard
            </Button>
          </Box>
        </Grid>
      );
    }
    return (
      <Grid item xs={12} md={6} textAlign="center">
        <Box textAlign="center">
          <Typography variant="subtitle2" color="textSecondary">
            Complete {timerData.dailyGoal} NeoPom sessions to receive a
            photocard!
          </Typography>
        </Box>
      </Grid>
    );
  }

  if (userId === "nctzen") {
    return (
      <>
        <NeoZone />
        <EmptyState
          image={<NoDataIllustration />}
          title="Please create an account"
          description="Must have an account to access this page"
          button={
            <Fab variant="extended" color="primary" component={Link} to="/">
              <Box clone mr={1}>
                <HomeIcon />
              </Box>
              Home
            </Fab>
          }
        />
      </>
    );
  }

  if (status === "loading") {
    return <Loader />;
  }

  if (status === "error") {
    return (
      <EmptyState
        image={<ErrorIllustration />}
        title="Couldn’t retrieve user"
        description="Something went wrong when trying to retrieve the requested user"
        button={
          <Fab variant="extended" color="primary" component={Link} to="/">
            <Box clone mr={1}>
              <HomeIcon />
            </Box>
            Home
          </Fab>
        }
      />
    );
  }

  if (!userId) {
    return (
      <EmptyState
        image={<NoDataIllustration />}
        title="User doesn’t exist"
        description="The requested user doesn’t exist"
        button={
          <Fab variant="extended" color="primary" component={Link} to="/">
            <Box clone mr={1}>
              <HomeIcon />
            </Box>
            Home
          </Fab>
        }
      />
    );
  }

  return (
    <>
      <UpdateMessage userId={userId} data={data} />
      <RandomSelection
        userId={userId}
        open={rewarded}
        onClose={() => setRewarded(false)}
      />
      <RandomSelection2
        userId={userId}
        open={rewarded2}
        onClose={() => setRewarded2(false)}
      />
      <Grid container direction="column">
        <Grid className={classes.grid} container justify="center" spacing={4}>
          <Grid item xs={12} md={6} justify="center">
            <h1>YOUR NEOZONE</h1>
          </Grid>
        </Grid>

        <Grid className={classes.grid} container justify="center" spacing={4}>
          <Grid item xs={12} md={6} justify="center">
            <h3>TODAY'S PROGRESS</h3>
          </Grid>
        </Grid>

        <Grid className={classes.grid} container justify="center" spacing={4}>
          <Grid item xs={12} md={6} textAlign="center">
            <Box mt={2} p={2}>
              <Tooltip title="You can adjust your targets in your settings tab">
                {dailyData.sessionTotal < timerData.dailyGoal ||
                timerData.dailyGoal == timerData.stretchGoal ? (
                  <PrettoSlider
                    value={dailyData.sessionTotal}
                    valueLabelDisplay="on"
                    step={1}
                    marks={[
                      ...marks,
                      {
                        value: timerData.dailyGoal,
                        label: `Target: ${timerData.dailyGoal}`,
                      },
                    ]}
                    min={0}
                    max={timerData.dailyGoal}
                  />
                ) : (
                  <PrettoSlider
                    value={dailyData.sessionTotal}
                    valueLabelDisplay="on"
                    step={1}
                    marks={[
                      ...marks,
                      { value: timerData.dailyGoal, label: "Target" },
                      {
                        value: timerData.stretchGoal,
                        label: smallPage ? "Stretch" : "Stretch Goal",
                      },
                    ]}
                    min={0}
                    max={Math.max(
                      timerData.stretchGoal,
                      dailyData.sessionTotal
                    )}
                  />
                )}
              </Tooltip>
            </Box>
          </Grid>
        </Grid>

        <Grid
          className={classes.grid}
          container
          direction="column"
          alignItems="center"
          justify="center"
          spacing={4}
        >
          {/* <Grid
            container
            item
            xs={12}
            md={6}
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            {progressArray.map((isTrue) => (
              <Grid item xs={1}>
                {isTrue ? (
                  <CheckCircle fontSize="large" color="primary" />
                ) : (
                  <CircleIcon fontSize="large" className={classes.circleIcon} />
                )}
              </Grid>
            ))}
          </Grid> */}

          {renderMessage()}

          <Grid item xs={12} md={6} justify="center">
            <TimerDialog userId={userId} currentTasks={data.currentTasks} />
          </Grid>
        </Grid>

        {/*Add a info button to make this more informative*/}
        <Grid className={classes.grid} container justify="center" spacing={5}>
          <Grid item xs={12} md={6}>
            <h3>TODOS</h3>
            <TextField
              fullWidth
              error={Boolean(taskError)}
              helperText={taskError}
              label="Add a Task"
              value={taskText}
              variant="filled"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  addTask();
                }
              }}
              onChange={(e) => {
                if (taskError) {
                  setTaskError(null);
                }
                setTaskText(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="add task" onClick={addTask}>
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        {/* For current tasks */}
        <Grid className={classes.grid} container justify="center" spacing={4}>
          <Grid item xs={12} md={6}>
            {data.currentTasks && data.currentTasks.length > 0 ? (
              <>
                Currently working on
                <List>
                  {data.currentTasks.map((todo, i) => (
                    <Tooltip title="Mark as complete" placement="bottom-start">
                      <ListItem
                        className={classes.activeTask}
                        style={{
                          background:
                            theme.primaryColor.import[theme.dark ? 700 : 100],
                        }}
                        button
                        onClick={() => {
                          firestore
                            .collection("users")
                            .doc(userId)
                            .update({
                              finishedTasks: firebase.firestore.FieldValue.arrayUnion(
                                todo
                              ),
                              currentTasks: firebase.firestore.FieldValue.arrayRemove(
                                todo
                              ),
                            });
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar className={classes.greenAvatar}>
                            {/* <FolderIcon />
                             */}
                            <DoneIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={todo} />
                        <ListItemSecondaryAction>
                          <Tooltip title="Pause work on this task">
                            <IconButton edge="end" aria-label="delete">
                              <PauseIcon
                                onClick={() => {
                                  firestore
                                    .collection("users")
                                    .doc(userId)
                                    .update({
                                      currentTasks: firebase.firestore.FieldValue.arrayRemove(
                                        todo
                                      ),
                                      todos: firebase.firestore.FieldValue.arrayUnion(
                                        todo
                                      ),
                                    });
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </Tooltip>
                  ))}
                </List>
              </>
            ) : null}

            {data.todos ? (
              <List>
                {data.todos.map((todo, i) => (
                  <Tooltip
                    title="Start working on this task"
                    placement="bottom-start"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        firestore
                          .collection("users")
                          .doc(userId)
                          .update({
                            currentTasks: firebase.firestore.FieldValue.arrayUnion(
                              todo
                            ),
                            todos: firebase.firestore.FieldValue.arrayRemove(
                              todo
                            ),
                          });
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          {/* <FolderIcon />
                           */}
                          <PlayIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={todo} />
                      <ListItemSecondaryAction>
                        <Tooltip title="Delete task">
                          <IconButton edge="end" aria-label="delete">
                            <DeleteIcon
                              onClick={() => {
                                firestore
                                  .collection("users")
                                  .doc(userId)
                                  .update({
                                    todos: firebase.firestore.FieldValue.arrayRemove(
                                      todo
                                    ),
                                  });
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Tooltip>
                ))}
              </List>
            ) : null}

            {/* For finished tasks*/}
            {data.finishedTasks ? (
              <>
                <List>
                  {data.finishedTasks.map((todo, i) => (
                    <Tooltip
                      title="Mark as incomplete"
                      placement="bottom-start"
                    >
                      <ListItem
                        className={classes.finishedTask}
                        button
                        onClick={() => {
                          firestore
                            .collection("users")
                            .doc(userId)
                            .update({
                              currentTasks: firebase.firestore.FieldValue.arrayUnion(
                                todo
                              ),
                              finishedTasks: firebase.firestore.FieldValue.arrayRemove(
                                todo
                              ),
                            });
                        }}
                      >
                        <ListItemText primary={todo} />
                        <ListItemSecondaryAction>
                          <Tooltip title="Archive task in history">
                            <IconButton edge="end" aria-label="archive">
                              <ArchiveIcon
                                onClick={() => {
                                  firestore
                                    .collection("users")
                                    .doc(userId)
                                    .update({
                                      finishedTasks: firebase.firestore.FieldValue.arrayRemove(
                                        todo
                                      ),
                                      [`history.${TODAY}.finishedTasks`]: firebase.firestore.FieldValue.arrayUnion(
                                        todo
                                      ),
                                    });
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </Tooltip>
                  ))}
                </List>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Grid className={classes.grid} container justify="center" spacing={4}>
          <Grid item xs={12} md={6}>
            <ProgressHistory history={data.history} />
          </Grid>
        </Grid>
      </Grid>
      <NeoZone />
    </>
  );
}

export default UserPage;
