import React, { Component, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";

import PropTypes from "prop-types";

import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const Item = ({ value, index }) => {
  const props = value === 1 ? { color: "primary" } : null;
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot {...props} />
        {index < 7 ? <TimelineConnector /> : null}
      </TimelineSeparator>
      <TimelineContent></TimelineContent>
    </TimelineItem>
  );
};

const progress = [1, 1, 1, 1, 0, 0, 0, 0];

function ColorsTimeline() {
  return (
    <Timeline>
      {progress.map((x, index) => (
        <Item value={x} index={index} key={index} />
      ))}
    </Timeline>
  );
}
const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
}));

const ProgressBar = () => {
  return (
    <Box>
      <ColorsTimeline />
    </Box>
  );
};

export default ProgressBar;
