import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import firebase, { firestore } from "../../firebase";
import useFirestoreQuery from "../../utils/useFirestoreQuery";

export default function UpdateMessage({ userId, data }) {
  const [open, setOpen] = React.useState(true);
  if (data && data.updateReceived) return null;
  console.log("data", data);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    firestore.collection("users").doc(userId).update({
      updateReceived: true,
    });

    setOpen(false);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={10000}
        onClose={handleClose}
        message="Note: There was a previous issue that made the timer inaccurate if this app was minimized or inactive. If you are seeing this message, then that issue has been resolved. "
        action={
          <React.Fragment>
            <Button color="primary" size="small" onClick={handleClose}>
              Got It
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
