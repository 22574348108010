export const quotes = [
  {
    member: "Taeyong",
    quote:
      "You can be bad at things, you can stay weird. That’s you, that’s just the way you are and that’s cool. That’s human.",
    photo: require("../../assets/images/taeyong-1.jpeg"),
    filter: ["kindness"],
  },
  {
    member: "Taeyong",
    quote:
      "You're all people writing your own history. And since you all work so hard, you'll all become amazing people. Amazing people. Good people. Kind grown-ups. And you'll all brighten the world. You'll all be able to do well in whatever you do.",
    photo: require("../../assets/images/taeyong-2.jpeg"),
    filter: ["hope", "kindness"],
  },
  {
    member: "Taeyong",
    quote:
      "Even if it’s tiring, if you work little harder you’ll be able to see the light.",
    photo: require("../../assets/images/taeyong-3.jpg"),
    filter: ["motivation"],
  },
  {
    member: "Taeyong",
    quote:
      "In this vast universe, in a consistent time system, we breathe in the same air. We are all special and important. You all will always be shining and hot because of your high spirits.",
    photo: require("../../assets/images/taeyong-4.jpeg"),
    filter: ["hope", "kindness"],
  },
  {
    member: "Taeyong",
    quote:
      "Only by encountering many difficulties, knowing and experiencing those difficulties, then your strength to escape them will develop.",
    photo: require("../../assets/images/taeyong-5.jpeg"),
    filter: ["advice", "motivation"],
  },
  {
    member: "Taeyong",
    quote:
      "This  too shall pass, and time doesn't stop for anyone. So with time, I think you'll be able to learn how to enjoy that moment as well.",
    photo: require("../../assets/images/taeyong-6.jpg"),
    filter: [],
  },
  {
    member: "Taeyong",
    quote:
      "While you're diligently taking a step forward, don't forget to take some time looking up to the sky and see how it always opens for you.",
    photo: require("../../assets/images/taeyong-7.jpeg"),
    filter: ["kindness", "advice"],
  },
  {
    member: "Taeyong",
    quote:
      "Your efforts will never betray you. All of your efforts will pay off.",
    photo: require("../../assets/images/taeyong-8.jpg"),
    filter: ["motivation"],
  },
  {
    member: "Taeyong",
    quote:
      "You have to make sure you have faith in yourself. That never changes. If you're that kind of person, you just keep trusting yourself and just care about how you live this life of yours and that's going to make you happy enough.",
    photo: require("../../assets/images/taeyong-9.jpeg"),
    filter: ["hope", "advice"],
  },
  {
    member: "Taeyong",
    quote: "Don’t try to run away from it. If you have to do it, just do it.",
    photo: require("../../assets/images/taeyong-10.jpg"),
    filter: ["advice", "motivation"],
  },
  {
    member: "Taeyong",
    quote:
      "I hope you do whatever you want to do, and be whatever you want to be. Let's live on like that. Let's have a fun life.",
    filter: ["hope"],
  },
  {
    member: "Taeyong",
    quote:
      "Everything will be fine because we're already doing our best so don't overthink it too much.",
    filter: ["advice"],
  },
  {
    member: "Taeyong",
    quote: "I know life is hard but I’ll always root for you.",
    filter: ["kindness"],
  },
  {
    member: "Renjun",
    quote:
      "It's okay to try and find our dream. It's common to try different things before finding something that you really love.",
    filter: ["hope"],
  },
  {
    member: "Renjun",
    quote:
      "I don't really mind what title others give me. I personally have my own goals and ambitions, and that's more important to me. My self-evaluation matters above all else. If I think I am an artist, then I am.",
    filter: ["advice"],
  },
  {
    member: "Renjun",
    quote: "After experiencing pain, you'll be able to be a better person.",
    filter: ["advice"],
  },
  {
    member: "Renjun",
    quote:
      "A large goal is still okay, but make sure to give yourself little targets to complete, so you can feel accomplished and that in itself is motivating!",
    filter: ["advice", "motivation"],
  },
  {
    member: "Renjun",
    quote:
      "It's unavoidable to have some regrets in life. A lot of times we regret it when we have lost something or look back to the past, but there's no undo button in life. We should look forward to a new week and give ourselves chances at a new start.",
    filter: ["hope", "advice"],
  },
  {
    member: "Renjun",
    quote:
      "When you think about a task, there are only two options, to do it or not do it. If you choose the second option and not do it, then you will face this question again and again. Since there are only two options, it's best to save time and save yourself from stress.",
    filter: ["advice"],
  },
  {
    member: "Renjun",
    quote:
      'When you\'re looking at someone succeeding and improving, don\'t let it affect you negatively. Instead of thinking "that person is improving more" and giving up, you should think about "how can we improve and succeed together".',
    filter: ["advice"],
  },
  {
    member: "Renjun",
    quote:
      "Watching people around you succeed could feel frustrating. But life is like a game of poker, you can't guarantee that you will have a good draw. No matter if it's a bad hand you should still keep playing because, in the end, this means that you will be the winner at life.",
    filter: ["advice"],
  },
  {
    member: "Renjun",
    quote:
      "At times you might feel like you're by yourself in this world, but in reality, you have people who love and care for you surrounding you.",
    filter: ["kindness"],
  },
  {
    member: "Renjun",
    quote:
      "Loneliness can also teach us many things. As long as you stay courageous, you can overcome loneliness.",
    filter: ["advice"],
  },
  {
    member: "Renjun",
    quote:
      "Sometimes we live our lives and wonder why we're facing such difficulties. But like the analogy, it might just mean that you're doing your best. Don't be discouraged!",
    filter: ["motivation"],
  },
  {
    member: "Renjun",
    quote:
      "If you have yet to decide on a dream, it's good to try out all different things and discover it for yourself!",
    filter: ["hope", "advice"],
  },
  {
    member: "Renjun",
    quote:
      "Tomorrow's unluckiness was all used up today, so that means tomorrow you will have a perfect day!",
    filter: ["hope"],
  },
  {
    member: "Renjun",
    quote:
      "Don't think small action would be meaningless. Starting with ourselves, let's change the world together.",
    filter: ["hope", "motivation"],
  },
  {
    member: "Renjun",
    quote:
      "We should also see the stresses in our life as a dessert. Don't let it become something serious, let it become something sweet.",
    filter: ["advice", "motivation"],
  },
  {
    member: "Renjun",
    quote:
      'That phrase "You live for yourself alone" can teach you lots of resilience.',
    filter: ["advice"],
  },
  {
    member: "Renjun",
    quote:
      "Do whatever you want to do, your dream is yours alone, don't worry about what others think about it.",
    filter: ["advice"],
  },
  {
    member: "Renjun",
    quote:
      "It doesn't matter about others, as long as you made your own choice and you are happy that's all that matters.",
    filter: ["advice", "kindness"],
  },
  {
    member: "Renjun",
    quote:
      "Our future is always in our hands. Although we might experience hardships along the way, as long as you see it through and believe in your goal, the results will not betray you.",
    filter: ["hope", "kindness", "advice"],
  },
  {
    member: "Renjun",
    quote:
      "If you never try, then you'll never know the outcome! Take the first step to it try out so we can see the result for ourselves.",
    filter: ["hope", "advice", "motivation"],
  },
  {
    member: "Renjun",
    quote:
      "There's a bright sun inside of everyone, it's up to you to discover it. I believe that when you're working hard to achieve something, you will shine brightly. Although you might not see the brightness yourself, it will show in everyone elses eyes.",
    filter: ["hope", "kindness", "motivation"],
  },
  {
    member: "Renjun",
    quote:
      "Even if there is no path, when many people walk that direction then a path will eventually appear. If there is no path ahead then it's up to you to take the first step and make one for yourself.",
    filter: ["hope", "motivation"],
  },
  {
    member: "Renjun",
    quote:
      "When we think that life is difficult, in the end, you will realize it's not difficult because you made the wrong choices, instead it's because you made the correct choices.",
    filter: ["advice"],
  },
  {
    member: "Renjun",
    quote:
      "Being happy every day is difficult. You need to be able to experience sadness. This way you can feel real happiness when it comes to you.",
    filter: ["advice"],
  },
  {
    member: "Renjun",
    quote:
      "There isn't an instruction manual or rule book to life, there is nothing stopping you from achieving your dream, it's never too late to start chasing your dream.",
    filter: ["hope", "motivation"],
  },
  {
    member: "Renjun",
    quote:
      "Self-confidence is something very important, give yourself more confidence, tell yourself 'I love myself a lot, I am confident'.",
    filter: ["advice"],
  },
  {
    member: "Johnny",
    quote:
      "You’re doing great. You can do it. You’re moving. You’re going somewhere, you know? I just wanna say thank you for you know just being you!",
    filter: ["hope", "kindness", "motivation"],
  },
  {
    member: "Ten",
    quote:
      "I hope you find what you want to do and if you want to do something, don’t be afraid to do so, and do it with fun. If later you think that this is not it, you can always just find something else. Don’t give yourself too much pressure. You need to try a few things to know what you truly like.",
    filter: ["hope", "kindness"],
  },
  {
    member: "Renjun",
    quote:
      "In the past I tried to appear stronger on the outside, but now I try to focus on the inside to truly become stronger. You have to have your own thoughts and ideas so others cannot shake you.",
    filter: ["advice"],
  },
];
