import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSpring, animated as a } from "react-spring";
import shuffle from "lodash/shuffle";
import firebase, { firestore } from "../../firebase";
import useFirestoreQuery from "../../utils/useFirestoreQuery";

import {
  Box,
  Typography,
  Grid,
  Paper,
  Avatar,
  IconButton,
  GridList,
  GridListTile,
  useMediaQuery,
  LinearProgress,
  ButtonBase,
  CircularProgress,
} from "@material-ui/core";
import { Link as LinkIcon, Help as HelpIcon, ArrowRightAlt } from "@material-ui/icons";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

import { calcStats, stats } from "./utils";
import Loader from "../Loader";
import InfoSection from "./InfoSection";
import useWidth from "../../utils/useWidth";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  timer: {
    background: "green", //"linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    flex: 1,
    padding: "2rem",
    position: "relative",
    top: "100px",
    textAlign: "center",
  },
  features: {
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  grid: {
    // flexGrow: 1,
    padding: theme.spacing(3),
    width: "70%",
    margin: "auto",
  },
  card: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    direction: "row",
    height: 300,
  },
  avatar: {
    margin: "auto",
    marginBottom: theme.spacing(2),
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  context: {
    color: "gray",
  },
  image: {
    // flex: 1,
    // height: 380,
    height: 290,
    width: 215,
    resizeMode: "cover",
    borderRadius: theme.spacing(2),
    position: "absolute",
  },
  cover: {
    // flex: 1,
    borderRadius: theme.spacing(2),

    height: 290,
    width: 215,
    background: theme.primaryColor.import[300],
    position: "absolute",
  },
  helpIcon: {
    height: "100%",
  },
  gridList: {
    width: "100%",
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    // transform: "translateZ(0)",
  },
  statCard: {
    height: "100%",
    textAlign: "left",
    padding: theme.spacing(2),
    background: theme.palette.action.selected,
  },
  grayBox: {
    textAlign: "center",
    width: "100%",
    background: theme.palette.action.selected,
  },
}));

const Photocard = ({ pc, variant }) => {
  const classes = useStyles();
  const [flipped, set] = useState(false);
  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(600px) rotateY(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  const regex = /[^.]+/g;
  const src = pc.path.match(regex)[0] + ".jpeg";

  if (variant === "flippable") {
    return (
      <Grid item xs={12}>
        <Card className={classes.card}>
          <ButtonBase
            focusRipple
            onClick={() => set((state) => !state)}
            onMouseOver={() => set(true)}
            onMouseOut={() => set(false)}
          >
            {/* <a.div
          style={{
            opacity: opacity.interpolate((o) => 1 - o),
            transform,
            backgroundImage: pc.path,
          }}
        /> */}
            <a.img
              className={classes.image}
              src={pc.path}
              alt={pc.member}
              style={{
                opacity,
                transform: transform.interpolate((t) => `${t} rotateY(180deg)`),
              }}
            />
            {/* </a.div> */}
            <a.div className={classes.cover} style={{ opacity: opacity.interpolate((o) => 1 - o), transform }}>
              <HelpIcon fontSize="large" className={classes.helpIcon} />
            </a.div>
          </ButtonBase>
        </Card>
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <Card className={classes.card}>
        <a.img className={classes.image} src={src} alt={pc.member} />
      </Card>
    </Grid>
  );
};

function LinearProgressWithLabel(props) {
  let calcPercent = Math.floor((props.value / props.total) * 100);
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={calcPercent} />
      </Box>
      <Box>
        <Typography variant="body2" color="textSecondary">
          {props.total}
        </Typography>
      </Box>
    </Box>
  );
}

function StatBox({ label, total, numCollected }) {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={3}>
      <Box className={classes.statCard}>
        <Typography variant="h5">{label}</Typography>
        <LinearProgressWithLabel color="primary" value={numCollected} total={total} />
        <Typography color="textSecondary">
          <b>{numCollected} collected</b>
        </Typography>
      </Box>
    </Grid>
  );
}

function PhotocardPage(props) {
  const classes = useStyles();

  const { userId } = props;
  const { data, status, _error } = useFirestoreQuery(firestore.collection("users").doc(userId || "emptyUser"));
  let userStats = {};
  if (data && data.photocards) {
    userStats = calcStats(data.photocards);
  }

  let numCols;
  const width = useWidth();

  switch (width) {
    case "xs":
      numCols = 1;
      break;

    case "sm":
      numCols = 3;
      break;

    case "md":
      numCols = 4;
      break;

    case "lg":
      numCols = 5;
      break;

    case "xl":
      numCols = 7;
      break;

    default:
      numCols = 4;
      break;
  }

  if (status === "loading") {
    return <Loader />;
  }

  return (
    <Box
      style={{
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
      textAlign="center"
    >
      <Box m={2} textAlign="left">
        <Box display="flex" direction="row">
          <h3>Overview</h3>
          <InfoSection />
        </Box>
        <Grid container spacing={3} style={{ flexGrow: 1 }}>
          <StatBox label="NCT" total={stats["NCT"]} numCollected={userStats["NCT"] || 0} />
          <StatBox label="NCT 127" total={stats["127"]} numCollected={userStats["127"] || 0} />
          <StatBox label="NCT Dream" total={stats["Dream"]} numCollected={userStats["Dream"] || 0} />
          <StatBox label="WayV" total={stats["WayV"]} numCollected={userStats["WayV"] || 0} />
        </Grid>
      </Box>
      <Box m={2} textAlign="left">
        <h3>My Collection</h3>
      </Box>

      {data && data.photocards ? (
        <GridList cellHeight={300} cols={numCols}>
          {data.photocards.map((pc) => (
            <GridListTile cols={1}>
              <Photocard pc={pc} />
            </GridListTile>
          ))}
        </GridList>
      ) : (
        <Box pl={2} pr={2}>
          <Box p={2} className={classes.grayBox}>
            No Photocards Collected
          </Box>
        </Box>
      )}
    </Box>
  );

  return null;
}

PhotocardPage.defaultProps = {
  type: "page",
  size: "medium",
  padding: 2,
};

PhotocardPage.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  padding: PropTypes.number,

  image: PropTypes.element,
  title: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.element,
};

export default PhotocardPage;
