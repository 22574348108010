import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSpring, animated as a } from "react-spring";
import sample from "lodash/sample";
import moment from "moment";
import firebase, { firestore } from "../../firebase";
import useFirestoreQuery from "../../utils/useFirestoreQuery";
import Loader from "../Loader";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tooltip,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as GiftIllustration } from "../../illustrations/gift.svg";

import { Close as CloseIcon } from "@material-ui/icons";

import photocard from "./utils";

const GIFT_GIF = require("../../assets/gift.png");
const QUESTION_IMG = require("../../assets/question.png");
const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  content: {
    height: 300,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  image: {
    height: 300,
    width: 225,
    resizeMode: "cover",
    borderRadius: theme.spacing(2),
    position: "absolute",
    top: 0,
  },
  gift: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    height: 300,
    width: 300,
    resizeMode: "cover",
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    padding: theme.spacing(2),
    textAlign: "center",
  },
  message: {
    whiteSpace: "pre-line",
  },
}));

function WelcomeDialog(props) {
  const [revealed, setRevealed] = useState(false);
  const { opacity, transform } = useSpring({
    opacity: revealed ? 1 : 0,
    transform: `perspective(600px) rotateY(${revealed ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });
  const classes = useStyles();
  let selectedPC;
  const { userId } = props;
  const { data, status, _error } = useFirestoreQuery(
    firestore.collection("users").doc(userId)
  );

  const title = "Thanks for joining!";

  const message = `Here's a free photocard to get you started!`;

  if (status === "loading") {
    return <Loader />;
  }

  // Logic for randomly selecting and revealing a photocard
  const handleReveal = () => {
    let choices;
    if (data && data.photocards) {
      // Limit choices to photos that user doesn't have
      choices = photocard.photocards.filter(
        (pc) => !data.photocards.includes(pc)
      );
    } else {
      choices = photocard.photocards;
    }
    selectedPC = sample(choices);

    firestore
      .collection("users")
      .doc(userId)
      .update({
        receivedFreebies: true,
        freePC: selectedPC,
        photocards: firebase.firestore.FieldValue.arrayUnion(selectedPC),
      });

    setRevealed(true);
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <DialogTitle disableTypography>
        <Typography variant="h6">{title}</Typography>

        <Tooltip title="Close">
          <IconButton className={classes.closeButton} onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <DialogContent>
        <Box className={classes.content}>
          <a.img
            className={classes.image}
            src={Boolean(data && data.freePC) ? data.freePC.path : QUESTION_IMG}
            style={{
              opacity,
              transform: transform.interpolate((t) => `${t} rotateY(180deg)`),
            }}
          />
          <a.img
            src={GIFT_GIF}
            alt="Gift"
            style={{ opacity: opacity.interpolate((o) => 1 - o), transform }}
            className={classes.gift}
          />
        </Box>
      </DialogContent>
      <DialogActions textAlign="center">
        <Box className={classes.actions}>
          <Typography variant="subtitle2" className={classes.message}>
            {revealed
              ? `You got ${
                  Boolean(data.freePC)
                    ? `${data.freePC.member}!\n Enter Neo Zone to earn more photocards. `
                    : "..."
                }`
              : message}
          </Typography>
          <br />
          {revealed ? null : (
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={handleReveal}
            >
              Click to reveal
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
}

WelcomeDialog.propTypes = {
  user: PropTypes.object,
};

export default WelcomeDialog;
