import React, { Component, useEffect } from "react";
import { Box, Typography, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./sliders.css";
import Slider from "react-slick";

import useWidth from "../../utils/useWidth";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    [theme.breakpoints.down("sm")]: {
      borderRadius: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      borderRadius: theme.spacing(4),
    },
    resizeMode: "cover",
  },
  quote: {
    background: theme.primaryColor.import[700],
    color: "white",
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
    minHeight: "250px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    margin: theme.spacing(2),
  },
}));

const pc_data = [
  {
    imgPath: require("../../assets/photocards/jaehyun-5.jpeg"),
  },
  {
    imgPath: require("../../assets/photocards/taeyong-2.jpeg"),
  },
  {
    imgPath: require("../../assets/photocards/renjun-1.jpeg"),
  },
  {
    imgPath: require("../../assets/photocards/hendery-2.jpeg"),
  },
  {
    imgPath: require("../../assets/photocards/lucas-3.jpeg"),
  },
  {
    imgPath: require("../../assets/photocards/johnny-2.jpeg"),
  },
  {
    imgPath: require("../../assets/photocards/jeno-1.jpeg"),
  },
  {
    imgPath: require("../../assets/photocards/jaemin-2.jpeg"),
  },
  {
    imgPath: require("../../assets/photocards/mark-2.jpeg"),
  },
  {
    imgPath: require("../../assets/photocards/haechan-1.jpeg"),
  },
  {
    imgPath: require("../../assets/photocards/chenle-1.jpeg"),
  },
  {
    imgPath: require("../../assets/photocards/winwin-1.jpeg"),
  },
  {
    imgPath: require("../../assets/photocards/xiaojun-1.jpeg"),
  },
  {
    imgPath: require("../../assets/photocards/yangyang-1.jpeg"),
  },
  {
    imgPath: require("../../assets/photocards/shotaro-1.jpeg"),
  },
  {
    imgPath: require("../../assets/photocards/sungchan-1.jpeg"),
  },
  {
    imgPath: require("../../assets/photocards/doyoung-2.jpeg"),
  },
  {
    imgPath: require("../../assets/photocards/taeil-1.jpeg"),
  },
  {
    imgPath: require("../../assets/photocards/yuta-1.jpeg"),
  },
  {
    imgPath: require("../../assets/photocards/jungwoo-1.jpeg"),
  },
  {
    imgPath: require("../../assets/photocards/ten-1.jpeg"),
  },
  {
    imgPath: require("../../assets/photocards/jisung-1.jpeg"),
  },
  {
    imgPath: require("../../assets/photocards/kun-1.jpeg"),
  },
];

export const PhotocardSlider = () => {
  const classes = useStyles();
  const width = useWidth();
  let height;
  let sliderWidth;

  switch (width) {
    case "xs":
      height = 200;

      sliderWidth = "100%";
      break;
    case "sm":
      height = 250;
      sliderWidth = "90%";
      break;
    case "md":
      height = 300;
      sliderWidth = "85%";
      break;

    case "lg":
    case "xl":
      height = 300;
      sliderWidth = "80%";
      break;

    default:
      height = 300;
      sliderWidth = "70%";
      break;
  }

  const pc_settings = {
    speed: 1250,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    lazyLoad: true,
  };

  return (
    <Box width={sliderWidth} p={2}>
      <Slider {...pc_settings}>
        {pc_data.map((pc) => (
          <img
            className={classes.img}
            style={{ height, width: height / 1.5 }}
            src={pc.imgPath}
            alt={pc.imgPath}
          />
        ))}
      </Slider>
    </Box>
  );
};

const quotes = [
  {
    member: "Renjun",
    quote:
      "Our future is always in our hands. Although we might experience hardships along the way, as long as you see it through and believe in your goal, the results will not betray you.",
  },
  {
    member: "Johnny",
    quote:
      "You’re doing great. You can do it. You’re moving. You’re going somewhere, you know? I just wanna say thank you for you know just being you!",
  },
  {
    member: "Taeyong",
    quote:
      "While you're diligently taking a step forward, don't forget to take some time looking up to the sky and see how it always opens for you.",
  },
  {
    member: "Ten",
    quote:
      "I hope you find what you want to do and if you want to do something, don’t be afraid to do so, and do it with fun.",
  },
];

const QuoteCard = ({ quote, index }) => {
  const classes = useStyles();

  return (
    <Box className={classes.quote}>
      <Typography variant="h5" style={{ fontWeight: 300 }}>
        {quote.member} says:
        <br />
      </Typography>
      <Typography variant="h6" component="i" style={{ whiteSpace: "pre-line" }}>
        "{quote.quote}" <br />
      </Typography>
    </Box>
  );
};

const quote_settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export const QuoteSlider = () => {
  const classes = useStyles();
  return (
    <div>
      <Slider {...quote_settings}>
        {quotes.map((quote, index) => (
          <QuoteCard quote={quote} key={index} />
        ))}
      </Slider>
    </div>
  );
};
