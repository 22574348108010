import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import { useLocation, Link } from "react-router-dom";

import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  ButtonGroup,
  Button,
  IconButton,
  Divider,
  Menu,
  MenuItem,
  Hidden,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import UserAvatar from "../UserAvatar";
import { useTheme } from "@material-ui/core/styles";
import Loader from "../Loader";
import useFirestoreQuery from "../../utils/useFirestoreQuery";
import { auth, firestore } from "../../firebase";

function Bar(props) {
  // For user edits
  const [menuAnchor, setMenuAnchor] = useState(null);
  const openMenu = (event) => setMenuAnchor(event.currentTarget);
  const closeMenu = () => setMenuAnchor(false);

  // For navigating to other pages
  const [navAnchor, setNavAnchor] = useState(null);
  const openNav = (event) => setNavAnchor(event.currentTarget);
  const closeNav = () => setNavAnchor(false);
  const theme = useTheme();
  const location = useLocation();

  // For welcoming a user when they sign up
  const { performingAction, user, userData, roles } = props;
  // Events

  const {
    onAboutClick,
    onSettingsClick,
    onSignOutClick,
    onSignUpClick,
    onSignInClick,
  } = props;

  const menuItems = [
    {
      name: "Settings",
      onClick: onSettingsClick,
    },
    {
      name: "Sign out",
      divide: true,
      onClick: onSignOutClick,
    },
  ];

  return (
    <>
      <AppBar
        // color="primary"
        position="static"
        style={{
          padding: theme.spacing(1),
          background: `linear-gradient(135deg,${theme.primaryColor.import[300]},${theme.primaryColor.import[800]})`,
        }}
      >
        <Toolbar>
          <Box
            display="flex"
            // flexGrow={1}
            alignItems="center"
            textAlign="center"
          >
            <Hidden smUp>
              <IconButton
                color="inherit"
                disabled={performingAction}
                onClick={openNav}
              >
                <MenuIcon />
              </IconButton>

              <Menu
                anchorEl={navAnchor}
                open={Boolean(navAnchor)}
                onClose={closeNav}
              >
                {location.pathname !== "/" && (
                  <MenuItem onClick={closeNav} component={Link} to={"/"}>
                    Home
                  </MenuItem>
                )}
                <MenuItem onClick={closeNav} component={Link} to={"/quotes"}>
                  Quotes
                </MenuItem>
                <MenuItem
                  onClick={closeNav}
                  component={Link}
                  to={"/photocards"}
                >
                  Collection
                </MenuItem>
                <MenuItem
                  onClick={closeNav}
                  component={Link}
                  to={user ? `/user/${user.uid}` : "/user/nctzen"}
                >
                  Neo Zone
                </MenuItem>
                <MenuItem onClick={onAboutClick}>About</MenuItem>
              </Menu>
            </Hidden>
            <Button component={Link} to={"/"}>
              <Typography
                variant="h6"
                style={{
                  fontWeight: 700,
                  textTransform: "none",
                  color: theme.palette.primary.contrastText,
                }}
              >
                {/* {process.env.REACT_APP_TITLE} */}
                {/* {location.pathname} */}
                Neopom
              </Typography>
            </Button>
          </Box>

          <Hidden smUp>
            <Box flexGrow={1} />
          </Hidden>

          <Hidden xsDown>
            <Box
              flexGrow={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                component={Link}
                to={"/quotes"}
                style={{
                  color: theme.palette.primary.contrastText,
                  marginLeft: theme.spacing(1),
                  marginRight: theme.spacing(1),
                  opacity: 0.65,
                }}
              >
                Quotes
              </Button>
              <Button
                component={Link}
                to={"/photocards"}
                style={{
                  color: theme.palette.primary.contrastText,
                  marginLeft: theme.spacing(1),
                  marginRight: theme.spacing(1),
                  opacity: 0.65,
                }}
              >
                Collection
              </Button>
              <Button
                component={Link}
                to={user ? `/user/${user.uid}` : "/user/nctzen"}
                style={{
                  color: theme.palette.primary.contrastText,
                  marginLeft: theme.spacing(1),
                  marginRight: theme.spacing(1),
                  opacity: 0.65,
                }}
              >
                NeoZone
              </Button>
              <Button
                onClick={onAboutClick}
                style={{
                  color: theme.palette.primary.contrastText,
                  marginLeft: theme.spacing(1),
                  marginRight: theme.spacing(1),
                  opacity: 0.65,
                }}
              >
                About
              </Button>
            </Box>
          </Hidden>

          {user && (
            <>
              {roles.includes("admin") && (
                <Box mr={1}>
                  <Button
                    color="inherit"
                    component={Link}
                    to="/admin"
                    variant="outlined"
                  >
                    Admin
                  </Button>
                </Box>
              )}

              <IconButton
                color="inherit"
                disabled={performingAction}
                onClick={openMenu}
              >
                <UserAvatar user={Object.assign(user, userData)} />
              </IconButton>

              <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={closeMenu}
              >
                {menuItems.map((menuItem, index) => {
                  if (
                    menuItem.hasOwnProperty("condition") &&
                    !menuItem.condition
                  ) {
                    return null;
                  }

                  let component = null;

                  if (menuItem.to) {
                    component = (
                      <MenuItem
                        key={index}
                        component={Link}
                        to={menuItem.to}
                        onClick={closeMenu}
                      >
                        {menuItem.name}
                      </MenuItem>
                    );
                  } else {
                    component = (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          closeMenu();

                          menuItem.onClick();
                        }}
                      >
                        {menuItem.name}
                      </MenuItem>
                    );
                  }

                  if (menuItem.divide) {
                    return (
                      <span key={index}>
                        <Divider />

                        {component}
                      </span>
                    );
                  }

                  return component;
                })}
              </Menu>
            </>
          )}

          {!user && (
            <Box display="flex" flexDirection="row">
              <Button
                onClick={onSignInClick}
                style={{
                  color: theme.palette.primary.contrastText,
                  marginRight: theme.spacing(2),
                  opacity: 0.65,
                }}
              >
                Sign in
              </Button>
              <Button
                variant="outlined"
                style={{
                  color: theme.palette.primary.contrastText,
                  borderColor: theme.palette.primary.contrastText,
                }}
                onClick={onSignUpClick}
              >
                Sign up
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}

Bar.defaultProps = {
  performingAction: false,
};

Bar.propTypes = {
  // Properties
  performingAction: PropTypes.bool.isRequired,
  user: PropTypes.object,
  userData: PropTypes.object,

  // Events
  onAboutClick: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onSignOutClick: PropTypes.func.isRequired,
};

export default Bar;
