import _ from "lodash";

const photocard = {};
photocard.filePath = "../../assets/photocards";

// Organize photocards by name, group
photocard.photocards = [
  {
    path: require("../../assets/photocards/chenle-1.jpeg"),
    member: "Chenle",
    key: "chenle-1",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/chenle-2.jpeg"),
    member: "Chenle",
    key: "chenle-2",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/chenle-3.jpeg"),
    member: "Chenle",
    key: "chenle-3",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/chenle-4.jpeg"),
    member: "Chenle",
    key: "chenle-4",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/chenle-5.jpeg"),
    member: "Chenle",
    key: "chenle-5",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/chenle-6.jpeg"),
    member: "Chenle",
    key: "chenle-6",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/chenle-7.jpeg"),
    member: "Chenle",
    key: "chenle-7",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/chenle-8.jpeg"),
    member: "Chenle",
    key: "chenle-8",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/chenle-9.jpeg"),
    member: "Chenle",
    key: "chenle-9",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/chenle-10.jpeg"),
    member: "Chenle",
    key: "chenle-10",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/doyoung-1.jpeg"),
    member: "Doyoung",
    key: "doyoung-1",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/doyoung-2.jpeg"),
    member: "Doyoung",
    key: "doyoung-2",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/doyoung-3.jpeg"),
    member: "Doyoung",
    key: "doyoung-3",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/doyoung-4.jpeg"),
    member: "Doyoung",
    key: "doyoung-4",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/doyoung-5.jpeg"),
    member: "Doyoung",
    key: "doyoung-5",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/doyoung-6.jpeg"),
    member: "Doyoung",
    key: "doyoung-6",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/doyoung-7.jpeg"),
    member: "Doyoung",
    key: "doyoung-7",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/doyoung-8.jpeg"),
    member: "Doyoung",
    key: "doyoung-8",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/doyoung-9.jpeg"),
    member: "Doyoung",
    key: "doyoung-9",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/doyoung-10.jpeg"),
    member: "Doyoung",
    key: "doyoung-10",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/haechan-1.jpeg"),
    member: "Haechan",
    key: "haechan-1",
    units: ["NCT", "127", "Dream"],
  },
  {
    path: require("../../assets/photocards/haechan-2.jpeg"),
    member: "Haechan",
    key: "haechan-2",
    units: ["NCT", "127", "Dream"],
  },
  {
    path: require("../../assets/photocards/haechan-3.jpeg"),
    member: "Haechan",
    key: "haechan-3",
    units: ["NCT", "127", "Dream"],
  },
  {
    path: require("../../assets/photocards/haechan-4.jpeg"),
    member: "Haechan",
    key: "haechan-4",
    units: ["NCT", "127", "Dream"],
  },
  {
    path: require("../../assets/photocards/haechan-5.jpeg"),
    member: "Haechan",
    key: "haechan-5",
    units: ["NCT", "127", "Dream"],
  },
  {
    path: require("../../assets/photocards/haechan-6.jpeg"),
    member: "Haechan",
    key: "haechan-6",
    units: ["NCT", "127", "Dream"],
  },
  {
    path: require("../../assets/photocards/haechan-7.jpeg"),
    member: "Haechan",
    key: "haechan-7",
    units: ["NCT", "127", "Dream"],
  },
  {
    path: require("../../assets/photocards/haechan-8.jpeg"),
    member: "Haechan",
    key: "haechan-8",
    units: ["NCT", "127", "Dream"],
  },
  {
    path: require("../../assets/photocards/haechan-9.jpeg"),
    member: "Haechan",
    key: "haechan-9",
    units: ["NCT", "127", "Dream"],
  },
  {
    path: require("../../assets/photocards/haechan-10.jpeg"),
    member: "Haechan",
    key: "haechan-10",
    units: ["NCT", "127", "Dream"],
  },
  {
    path: require("../../assets/photocards/hendery-1.jpeg"),
    member: "Hendery",
    key: "hendery-1",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/hendery-2.jpeg"),
    member: "Hendery",
    key: "hendery-2",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/hendery-3.jpeg"),
    member: "Hendery",
    key: "hendery-3",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/hendery-4.jpeg"),
    member: "Hendery",
    key: "hendery-4",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/hendery-5.jpeg"),
    member: "Hendery",
    key: "hendery-5",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/hendery-6.jpeg"),
    member: "Hendery",
    key: "hendery-6",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/hendery-7.jpeg"),
    member: "Hendery",
    key: "hendery-7",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/hendery-8.jpeg"),
    member: "Hendery",
    key: "hendery-8",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/hendery-9.jpeg"),
    member: "Hendery",
    key: "hendery-9",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/hendery-10.jpeg"),
    member: "Hendery",
    key: "hendery-10",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/jaehyun-1.jpeg"),
    member: "Jaehyun",
    key: "jaehyun-1",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/jaehyun-2.jpeg"),
    member: "Jaehyun",
    key: "jaehyun-2",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/jaehyun-3.jpeg"),
    member: "Jaehyun",
    key: "jaehyun-3",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/jaehyun-4.jpeg"),
    member: "Jaehyun",
    key: "jaehyun-4",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/jaehyun-5.jpeg"),
    member: "Jaehyun",
    key: "jaehyun-5",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/jaehyun-6.jpeg"),
    member: "Jaehyun",
    key: "jaehyun-6",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/jaehyun-7.jpeg"),
    member: "Jaehyun",
    key: "jaehyun-7",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/jaehyun-8.jpeg"),
    member: "Jaehyun",
    key: "jaehyun-8",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/jaehyun-9.jpeg"),
    member: "Jaehyun",
    key: "jaehyun-9",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/jaehyun-10.jpeg"),
    member: "Jaehyun",
    key: "jaehyun-10",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/jaemin-1.jpeg"),
    member: "Jaemin",
    key: "jaemin-1",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jaemin-2.jpeg"),
    member: "Jaemin",
    key: "jaemin-2",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jaemin-3.jpeg"),
    member: "Jaemin",
    key: "jaemin-3",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jaemin-4.jpeg"),
    member: "Jaemin",
    key: "jaemin-4",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jaemin-5.jpeg"),
    member: "Jaemin",
    key: "jaemin-5",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jaemin-6.jpeg"),
    member: "Jaemin",
    key: "jaemin-6",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jaemin-7.jpeg"),
    member: "Jaemin",
    key: "jaemin-7",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jaemin-8.jpeg"),
    member: "Jaemin",
    key: "jaemin-8",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jaemin-9.jpeg"),
    member: "Jaemin",
    key: "jaemin-9",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jaemin-10.jpeg"),
    member: "Jaemin",
    key: "jaemin-10",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jeno-1.jpeg"),
    member: "Jeno",
    key: "jeno-1",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jeno-2.jpeg"),
    member: "Jeno",
    key: "jeno-2",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jeno-3.jpeg"),
    member: "Jeno",
    key: "jeno-3",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jeno-4.jpeg"),
    member: "Jeno",
    key: "jeno-4",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jeno-5.jpeg"),
    member: "Jeno",
    key: "jeno-5",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jeno-6.jpeg"),
    member: "Jeno",
    key: "jeno-6",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jeno-7.jpeg"),
    member: "Jeno",
    key: "jeno-7",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jeno-8.jpeg"),
    member: "Jeno",
    key: "jeno-8",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jeno-9.jpeg"),
    member: "Jeno",
    key: "jeno-9",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jeno-10.jpeg"),
    member: "Jeno",
    key: "jeno-10",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jisung-1.jpeg"),
    member: "Jisung",
    key: "jisung-1",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jisung-2.jpeg"),
    member: "Jisung",
    key: "jisung-2",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jisung-3.jpeg"),
    member: "Jisung",
    key: "jisung-3",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jisung-4.jpeg"),
    member: "Jisung",
    key: "jisung-4",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jisung-5.jpeg"),
    member: "Jisung",
    key: "jisung-5",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jisung-6.jpeg"),
    member: "Jisung",
    key: "jisung-6",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jisung-7.jpeg"),
    member: "Jisung",
    key: "jisung-7",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jisung-8.jpeg"),
    member: "Jisung",
    key: "jisung-8",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jisung-9.jpeg"),
    member: "Jisung",
    key: "jisung-9",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/jisung-10.jpeg"),
    member: "Jisung",
    key: "jisung-10",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/johnny-1.jpeg"),
    member: "Johnny",
    key: "johnny-1",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/johnny-2.jpeg"),
    member: "Johnny",
    key: "johnny-2",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/johnny-3.jpeg"),
    member: "Johnny",
    key: "johnny-3",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/johnny-4.jpeg"),
    member: "Johnny",
    key: "johnny-4",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/johnny-5.jpeg"),
    member: "Johnny",
    key: "johnny-5",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/johnny-6.jpeg"),
    member: "Johnny",
    key: "johnny-6",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/johnny-7.jpeg"),
    member: "Johnny",
    key: "johnny-7",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/johnny-8.jpeg"),
    member: "Johnny",
    key: "johnny-8",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/johnny-9.jpeg"),
    member: "Johnny",
    key: "johnny-9",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/johnny-10.jpeg"),
    member: "Johnny",
    key: "johnny-10",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/jungwoo-1.jpeg"),
    member: "Jungwoo",
    key: "jungwoo-1",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/jungwoo-2.jpeg"),
    member: "Jungwoo",
    key: "jungwoo-2",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/jungwoo-3.jpeg"),
    member: "Jungwoo",
    key: "jungwoo-3",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/jungwoo-4.jpeg"),
    member: "Jungwoo",
    key: "jungwoo-4",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/jungwoo-5.jpeg"),
    member: "Jungwoo",
    key: "jungwoo-5",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/jungwoo-6.jpeg"),
    member: "Jungwoo",
    key: "jungwoo-6",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/jungwoo-7.jpeg"),
    member: "Jungwoo",
    key: "jungwoo-7",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/jungwoo-8.jpeg"),
    member: "Jungwoo",
    key: "jungwoo-8",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/jungwoo-9.jpeg"),
    member: "Jungwoo",
    key: "jungwoo-9",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/jungwoo-10.jpeg"),
    member: "Jungwoo",
    key: "jungwoo-10",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/kun-1.jpeg"),
    member: "Kun",
    key: "kun-1",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/kun-2.jpeg"),
    member: "Kun",
    key: "kun-2",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/kun-3.jpeg"),
    member: "Kun",
    key: "kun-3",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/kun-4.jpeg"),
    member: "Kun",
    key: "kun-4",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/kun-5.jpeg"),
    member: "Kun",
    key: "kun-5",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/kun-6.jpeg"),
    member: "Kun",
    key: "kun-6",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/kun-7.jpeg"),
    member: "Kun",
    key: "kun-7",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/kun-8.jpeg"),
    member: "Kun",
    key: "kun-8",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/kun-9.jpeg"),
    member: "Kun",
    key: "kun-9",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/kun-10.jpeg"),
    member: "Kun",
    key: "kun-10",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/lucas-1.jpeg"),
    member: "Lucas",
    key: "lucas-1",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/lucas-2.jpeg"),
    member: "Lucas",
    key: "lucas-2",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/lucas-3.jpeg"),
    member: "Lucas",
    key: "lucas-3",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/lucas-4.jpeg"),
    member: "Lucas",
    key: "lucas-4",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/lucas-5.jpeg"),
    member: "Lucas",
    key: "lucas-5",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/lucas-6.jpeg"),
    member: "Lucas",
    key: "lucas-6",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/lucas-7.jpeg"),
    member: "Lucas",
    key: "lucas-7",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/lucas-8.jpeg"),
    member: "Lucas",
    key: "lucas-8",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/lucas-9.jpeg"),
    member: "Lucas",
    key: "lucas-9",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/lucas-10.jpeg"),
    member: "Lucas",
    key: "lucas-10",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/mark-1.jpeg"),
    member: "Mark",
    key: "mark-1",
    units: ["NCT", "127", "Dream"],
  },
  {
    path: require("../../assets/photocards/mark-2.jpeg"),
    member: "Mark",
    key: "mark-2",
    units: ["NCT", "127", "Dream"],
  },
  {
    path: require("../../assets/photocards/mark-3.jpeg"),
    member: "Mark",
    key: "mark-3",
    units: ["NCT", "127", "Dream"],
  },
  {
    path: require("../../assets/photocards/mark-4.jpeg"),
    member: "Mark",
    key: "mark-4",
    units: ["NCT", "127", "Dream"],
  },
  {
    path: require("../../assets/photocards/mark-5.jpeg"),
    member: "Mark",
    key: "mark-5",
    units: ["NCT", "127", "Dream"],
  },
  {
    path: require("../../assets/photocards/mark-6.jpeg"),
    member: "Mark",
    key: "mark-6",
    units: ["NCT", "127", "Dream"],
  },
  {
    path: require("../../assets/photocards/mark-7.jpeg"),
    member: "Mark",
    key: "mark-7",
    units: ["NCT", "127", "Dream"],
  },
  {
    path: require("../../assets/photocards/mark-8.jpeg"),
    member: "Mark",
    key: "mark-8",
    units: ["NCT", "127", "Dream"],
  },
  {
    path: require("../../assets/photocards/mark-9.jpeg"),
    member: "Mark",
    key: "mark-9",
    units: ["NCT", "127", "Dream"],
  },
  {
    path: require("../../assets/photocards/mark-10.jpeg"),
    member: "Mark",
    key: "mark-10",
    units: ["NCT", "127", "Dream"],
  },
  {
    path: require("../../assets/photocards/renjun-1.jpeg"),
    member: "Renjun",
    key: "renjun-1",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/renjun-2.jpeg"),
    member: "Renjun",
    key: "renjun-2",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/renjun-3.jpeg"),
    member: "Renjun",
    key: "renjun-3",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/renjun-4.jpeg"),
    member: "Renjun",
    key: "renjun-4",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/renjun-5.jpeg"),
    member: "Renjun",
    key: "renjun-5",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/renjun-6.jpeg"),
    member: "Renjun",
    key: "renjun-6",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/renjun-7.jpeg"),
    member: "Renjun",
    key: "renjun-7",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/renjun-8.jpeg"),
    member: "Renjun",
    key: "renjun-8",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/renjun-9.jpeg"),
    member: "Renjun",
    key: "renjun-9",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/renjun-10.jpeg"),
    member: "Renjun",
    key: "renjun-10",
    units: ["NCT", "Dream"],
  },
  {
    path: require("../../assets/photocards/taeil-1.jpeg"),
    member: "Taeil",
    key: "taeil-1",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/taeil-2.jpeg"),
    member: "Taeil",
    key: "taeil-2",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/taeil-3.jpeg"),
    member: "Taeil",
    key: "taeil-3",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/taeil-4.jpeg"),
    member: "Taeil",
    key: "taeil-4",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/taeil-5.jpeg"),
    member: "Taeil",
    key: "taeil-5",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/taeil-6.jpeg"),
    member: "Taeil",
    key: "taeil-6",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/taeil-7.jpeg"),
    member: "Taeil",
    key: "taeil-7",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/taeil-8.jpeg"),
    member: "Taeil",
    key: "taeil-8",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/taeil-9.jpeg"),
    member: "Taeil",
    key: "taeil-9",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/taeil-10.jpeg"),
    member: "Taeil",
    key: "taeil-10",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/taeyong-1.jpeg"),
    member: "Taeyong",
    key: "taeyong-1",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/taeyong-2.jpeg"),
    member: "Taeyong",
    key: "taeyong-2",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/taeyong-3.jpeg"),
    member: "Taeyong",
    key: "taeyong-3",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/taeyong-4.jpeg"),
    member: "Taeyong",
    key: "taeyong-4",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/taeyong-5.jpeg"),
    member: "Taeyong",
    key: "taeyong-5",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/taeyong-6.jpeg"),
    member: "Taeyong",
    key: "taeyong-6",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/taeyong-7.jpeg"),
    member: "Taeyong",
    key: "taeyong-7",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/taeyong-8.jpeg"),
    member: "Taeyong",
    key: "taeyong-8",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/taeyong-9.jpeg"),
    member: "Taeyong",
    key: "taeyong-9",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/taeyong-10.jpeg"),
    member: "Taeyong",
    key: "taeyong-10",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/ten-1.jpeg"),
    member: "Ten",
    key: "ten-1",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/ten-2.jpeg"),
    member: "Ten",
    key: "ten-2",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/ten-3.jpeg"),
    member: "Ten",
    key: "ten-3",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/ten-4.jpeg"),
    member: "Ten",
    key: "ten-4",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/ten-5.jpeg"),
    member: "Ten",
    key: "ten-5",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/ten-6.jpeg"),
    member: "Ten",
    key: "ten-6",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/ten-7.jpeg"),
    member: "Ten",
    key: "ten-7",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/ten-8.jpeg"),
    member: "Ten",
    key: "ten-8",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/ten-9.jpeg"),
    member: "Ten",
    key: "ten-9",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/ten-10.jpeg"),
    member: "Ten",
    key: "ten-10",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/winwin-1.jpeg"),
    member: "Winwin",
    key: "winwin-1",
    units: ["NCT", "127", "WayV"],
  },
  {
    path: require("../../assets/photocards/winwin-2.jpeg"),
    member: "Winwin",
    key: "winwin-2",
    units: ["NCT", "127", "WayV"],
  },
  {
    path: require("../../assets/photocards/winwin-3.jpeg"),
    member: "Winwin",
    key: "winwin-3",
    units: ["NCT", "127", "WayV"],
  },
  {
    path: require("../../assets/photocards/winwin-4.jpeg"),
    member: "Winwin",
    key: "winwin-4",
    units: ["NCT", "127", "WayV"],
  },
  {
    path: require("../../assets/photocards/winwin-5.jpeg"),
    member: "Winwin",
    key: "winwin-5",
    units: ["NCT", "127", "WayV"],
  },
  {
    path: require("../../assets/photocards/winwin-6.jpeg"),
    member: "Winwin",
    key: "winwin-6",
    units: ["NCT", "127", "WayV"],
  },
  {
    path: require("../../assets/photocards/winwin-7.jpeg"),
    member: "Winwin",
    key: "winwin-7",
    units: ["NCT", "127", "WayV"],
  },
  {
    path: require("../../assets/photocards/winwin-8.jpeg"),
    member: "Winwin",
    key: "winwin-8",
    units: ["NCT", "127", "WayV"],
  },
  {
    path: require("../../assets/photocards/winwin-9.jpeg"),
    member: "Winwin",
    key: "winwin-9",
    units: ["NCT", "127", "WayV"],
  },
  {
    path: require("../../assets/photocards/winwin-10.jpeg"),
    member: "Winwin",
    key: "winwin-10",
    units: ["NCT", "127", "WayV"],
  },
  {
    path: require("../../assets/photocards/xiaojun-1.jpeg"),
    member: "Xiaojun",
    key: "xiaojun-1",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/xiaojun-2.jpeg"),
    member: "Xiaojun",
    key: "xiaojun-2",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/xiaojun-3.jpeg"),
    member: "Xiaojun",
    key: "xiaojun-3",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/xiaojun-4.jpeg"),
    member: "Xiaojun",
    key: "xiaojun-4",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/xiaojun-5.jpeg"),
    member: "Xiaojun",
    key: "xiaojun-5",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/xiaojun-6.jpeg"),
    member: "Xiaojun",
    key: "xiaojun-6",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/xiaojun-7.jpeg"),
    member: "Xiaojun",
    key: "xiaojun-7",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/xiaojun-8.jpeg"),
    member: "Xiaojun",
    key: "xiaojun-8",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/xiaojun-9.jpeg"),
    member: "Xiaojun",
    key: "xiaojun-9",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/xiaojun-10.jpeg"),
    member: "Xiaojun",
    key: "xiaojun-10",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/yangyang-1.jpeg"),
    member: "Yangyang",
    key: "yangyang-1",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/yangyang-2.jpeg"),
    member: "Yangyang",
    key: "yangyang-2",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/yangyang-3.jpeg"),
    member: "Yangyang",
    key: "yangyang-3",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/yangyang-4.jpeg"),
    member: "Yangyang",
    key: "yangyang-4",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/yangyang-5.jpeg"),
    member: "Yangyang",
    key: "yangyang-5",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/yangyang-6.jpeg"),
    member: "Yangyang",
    key: "yangyang-6",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/yangyang-7.jpeg"),
    member: "Yangyang",
    key: "yangyang-7",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/yangyang-8.jpeg"),
    member: "Yangyang",
    key: "yangyang-8",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/yangyang-9.jpeg"),
    member: "Yangyang",
    key: "yangyang-9",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/yangyang-10.jpeg"),
    member: "Yangyang",
    key: "yangyang-10",
    units: ["NCT", "WayV"],
  },
  {
    path: require("../../assets/photocards/yuta-1.jpeg"),
    member: "Yuta",
    key: "yuta-1",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/yuta-2.jpeg"),
    member: "Yuta",
    key: "yuta-2",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/yuta-3.jpeg"),
    member: "Yuta",
    key: "yuta-3",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/yuta-4.jpeg"),
    member: "Yuta",
    key: "yuta-4",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/yuta-5.jpeg"),
    member: "Yuta",
    key: "yuta-5",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/yuta-6.jpeg"),
    member: "Yuta",
    key: "yuta-6",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/yuta-7.jpeg"),
    member: "Yuta",
    key: "yuta-7",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/yuta-8.jpeg"),
    member: "Yuta",
    key: "yuta-8",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/yuta-9.jpeg"),
    member: "Yuta",
    key: "yuta-9",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/yuta-10.jpeg"),
    member: "Yuta",
    key: "yuta-10",
    units: ["NCT", "127"],
  },
  {
    path: require("../../assets/photocards/sungchan-1.jpeg"),
    member: "Sungchan",
    key: "sungchan-1",
    units: ["NCT"],
  },
  {
    path: require("../../assets/photocards/shotaro-1.jpeg"),
    member: "Shotaro",
    key: "shotaro-1",
    units: ["NCT"],
  },
];

export const calcStats = (photocardList) => {
  const result = {};
  const allUnits = _.flatten(photocardList.map((pc) => pc.units));
  _.values(_.groupBy(allUnits)).forEach((d) => (result[d[0]] = d.length));
  return result;
};
photocard.calcStats = calcStats;

export const stats = photocard.calcStats(photocard.photocards);
photocard.stats = stats;

export default photocard;
