import React, { Component, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LoopIcon from "@material-ui/icons/Loop";
import difference from "lodash/difference";

import PropTypes from "prop-types";

import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useWidth from "../../utils/useWidth";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: "left",
  },

  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  icon: { fontSize: theme.spacing(4) },
  item: { margin: 0 },
  content: { flex: 10 },
  oppositeContent: { flex: 0.1, padding: 0 },
}));

const Item = ({ date, info, variant }) => {
  const classes = useStyles();
  const theme = useTheme();
  const finishedTasks = (info && info.finishedTasks) || [];
  let currentTasks = (info && info.currentTasks) || [];
  currentTasks = difference(currentTasks, finishedTasks);

  if (variant === "full") {
    return (
      <TimelineItem className={classes.item}>
        <TimelineOppositeContent className={classes.oppositeContent} />
        <TimelineSeparator>
          <TimelineDot>
            {info && info.sessionTotal ? (
              <Avatar className={classes.avatar}>{info.sessionTotal}</Avatar>
            ) : (
              <AssignmentIcon className={classes.icon} />
            )}
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent className={classes.content}>
          <Typography variant="body2" color="textSecondary" textAlign="left">
            {date}
          </Typography>
          <Box
            mt={2}
            className={classes.card}
            style={{
              background: theme.primaryColor.import[theme.dark ? 700 : 200],
            }}
          >
            {finishedTasks.length ? (
              <>
                <Typography variant="subtitle2" component="h1">
                  Tasks you finished
                </Typography>
                {finishedTasks.map((t, i) => (
                  <Typography variant="body" key={i}>
                    {`   - ${t}`}
                    <br />
                  </Typography>
                ))}
              </>
            ) : null}
            {currentTasks.length ? (
              <>
                <Typography variant="subtitle2" component="h1">
                  Tasks you worked on
                </Typography>
                {currentTasks.map((t, i) => (
                  <Typography variant="body" key={i}>
                    {`   - ${t}`}
                    <br />
                  </Typography>
                ))}
              </>
            ) : null}
            {!currentTasks.length && !finishedTasks.length ? (
              <Typography variant="subtitle2" component="h1">
                You completed {info.sessionTotal} session
                {info.sessionTotal > 1 && "s"}!
              </Typography>
            ) : null}
          </Box>
        </TimelineContent>
      </TimelineItem>
    );
  }

  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography variant="body2" color="textSecondary">
          {date}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot>
          {info && info.sessionTotal ? (
            <Avatar className={classes.avatar}>{info.sessionTotal}</Avatar>
          ) : (
            <AssignmentIcon className={classes.icon} />
          )}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Box
          className={classes.card}
          style={{
            background: theme.primaryColor.import[theme.dark ? 700 : 200],
          }}
        >
          {finishedTasks.length ? (
            <>
              <Typography variant="subtitle2" component="h1">
                Tasks you finished
              </Typography>
              {info.finishedTasks.map((t, i) => (
                <Typography variant="body" key={i}>
                  {`   - ${t}`}
                  <br />
                </Typography>
              ))}
            </>
          ) : null}
          {currentTasks.length ? (
            <>
              <Typography variant="subtitle2" component="h1">
                Tasks you worked on
              </Typography>
              {currentTasks.map((t, i) => (
                <Typography variant="body" key={i}>
                  {`   - ${t}`}
                  <br />
                </Typography>
              ))}
            </>
          ) : null}
          {!currentTasks.length && !finishedTasks.length ? (
            <Typography variant="subtitle2" component="h1">
              You completed {info.sessionTotal} session
              {info.sessionTotal > 1 && "s"}!
            </Typography>
          ) : null}
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};

const ProgressHistory = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const width = useWidth();
  if (!props.history || isEmpty(props.history)) return null;
  const history = Object.keys(props.history)
    .sort()
    .reverse()
    .reduce((obj, key) => {
      obj[key] = props.history[key];
      return obj;
    }, {});

  let align = "alternate";
  let variant;
  if (["xs", "sm"].includes(width)) {
    align = "left";
    variant = "full";
  }

  return (
    <>
      <h3>NEO HISTORY</h3>
      <Timeline align={align} p={1}>
        {Object.entries(history).map((e) => (
          <Item key={e[0]} date={e[0]} info={e[1]} variant={variant} />
        ))}
        <TimelineItem>
          {align === "left" && (
            <TimelineOppositeContent className={classes.oppositeContent} />
          )}
          <TimelineSeparator>
            <TimelineDot>
              <LoopIcon className={classes.icon} />
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent className={align === "left" && classes.content} />
        </TimelineItem>
      </Timeline>
    </>
  );
};

export default ProgressHistory;
