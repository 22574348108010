import React, { Component } from "react";

import PropTypes from "prop-types";

import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import { auth } from "../../firebase";

import HomePage from "../HomePage";
import AdminPage from "../AdminPage";
import UserPage from "../UserPage";
import NotFoundPage from "../NotFoundPage";
import QuotesScreen from "../QuotesScreen";
import PhotocardPage from "../Photocards/Page";

class Router extends Component {
  render() {
    // Properties
    const { roles, bar } = this.props;
    let { user } = this.props;

    if (!user) {
      try {
        user = auth.currentUser.uid;
      } catch (e) {
        console.log("error", e);
      }
    }

    // Functions
    const { openSnackbar } = this.props;

    return (
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
        {bar}

        <Switch>
          <Route path="/" exact>
            <HomePage user={user} openSnackbar={openSnackbar} />
          </Route>

          <Route path="/admin">
            {user && roles.includes("admin") ? (
              <AdminPage />
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route path="/quotes/:type">
            <QuotesScreen user={user} openSnackbar={openSnackbar} />
          </Route>

          <Route path="/quotes">
            <QuotesScreen user={user} openSnackbar={openSnackbar} />
          </Route>

          <Route path="/photocards">
            <PhotocardPage
              userId={user && user.uid}
              openSnackbar={openSnackbar}
            />
          </Route>

          <Route path="/user/:userId">
            <UserPage />
          </Route>

          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }
}

Router.propTypes = {
  // Properties
  user: PropTypes.object,
  roles: PropTypes.array.isRequired,
  bar: PropTypes.element,

  // Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default Router;
