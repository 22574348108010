import React, { useState } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";

import PropTypes from "prop-types";

import { withRouter, Link } from "react-router-dom";

import { auth } from "../../firebase";

import authentication from "../../services/authentication";

import MotivateDialog from "../MotivateDialog";

import { ReactComponent as TimerIllustration } from "../../illustrations/timer.svg";
import { ReactComponent as ChooseIllustration } from "../../illustrations/choose-task.svg";
import { ReactComponent as RelaxIllustration } from "../../illustrations/relax.svg";
import { ReactComponent as FocusedIllustration } from "../../illustrations/focused-person.svg";

import { PhotocardSlider, QuoteSlider } from "./Sliders";
import ProgressBar from "./Progress";
import useWidth from "../../utils/useWidth";
import SignUpDialog from "../SignUpDialog";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  color: { background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)" },
  image: {
    // background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    // border: 0,
    // borderRadius: 3,
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    // color: 'white',
    // height: 48,
    // padding: '0 30px',
  },
  timer: {
    // background: "green", //"linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    flex: 1,
    padding: "2rem",
    position: "relative",
    top: "100px",
    textAlign: "center",
  },
  features: {
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  grid: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  about: {
    flex: 1,
    padding: "2rem",
  },
}));

function HomePage(props) {
  const classes = useStyles();
  const { user } = props;

  let sliderOrder;
  let descOrder;
  const width = useWidth();
  const theme = useTheme();
  const [showSignUp, setShowSignUp] = useState(false);

  const signUpProps = {
    open: showSignUp,
    onClose: () => setShowSignUp(false),
  };

  switch (width) {
    case "xs":
      sliderOrder = 2;
      descOrder = 1;
      break;

    case "sm":
    case "md":
    case "lg":
    case "xl":
      sliderOrder = 1;
      descOrder = 2;
      break;

    default:
      sliderOrder = 1;
      descOrder = 2;
      break;
  }

  return (
    <>
      <Box
        style={{ transform: "translate(-50%, 0%)" }}
        position="relative"
        className={classes.image}
        left="50%"
        // top="10%"
        width="100%"
        height="100%"
        // top="50px"
      >
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              textAlign="left"
              display="flex"
              flexDirection="column"
              height="100%"
              color="primary"
              p={{ xs: 4, sm: 6, lg: 8 }}
              justifyContent="center"
            >
              <Typography
                variant="h3"
                display="inline"
                style={{ fontWeight: 800 }}
              >
                Work toward your{" "}
                <Typography
                  color="primary"
                  variant="h3"
                  display="inline"
                  style={{ fontWeight: 800 }}
                >
                  DREAMS
                </Typography>
              </Typography>
              <Typography variant="h4" style={{ fontWeight: 200 }}>
                one session at a time
              </Typography>
              <Box mt={2} mb={2}>
                <Typography variant="h6" color="textSecondary">
                  A productivity app tailor-made for NCTzens <br />
                </Typography>
              </Box>
              {user ? (
                <Button
                  component={Link}
                  to={`/user/${user.uid}`}
                  size="large"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  p={4}
                  style={{
                    background: `linear-gradient(135deg,${theme.primaryColor.import[300]},${theme.primaryColor.import[800]})`,
                  }}
                >
                  Enter Neo Zone
                </Button>
              ) : (
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => setShowSignUp(true)}
                  className={classes.button}
                  p={4}
                  style={{
                    background: `linear-gradient(135deg,${theme.primaryColor.import[300]},${theme.primaryColor.import[800]})`,
                  }}
                >
                  Get Started
                </Button>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <Box
              width="100%"
              height="100%"
              color="primary"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <FocusedIllustration width="90%" height="80%" />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* <Box
        // style={{ transform: "translate(0%, 50%)" }}
        className={classes.timer}
        width="100%"
        height="100px"
        background="primary"
        // color="inherit"
      >
        <TimerDialog />
      </Box> */}

      <Box
        // style={{ transform: "translate(0%, 50%)" }}
        className={classes.about}
        width="100%"
        top="50px"
        position="relative"
        // height="100px"
        // color="inherit"
        margin="auto"
        textAlign="center"
        style={{
          background: theme.primaryColor.import[theme.dark ? 700 : 200],
        }}
      >
        <Typography variant="h5" style={{ fontWeight: 600 }}>
          How It Works
        </Typography>

        <Box
          width={{ xs: "95%", sm: "90%", md: "80%", lg: "70%" }}
          margin="auto"
          mb={2}
        >
          <Typography variant="subtitle1" color="textSecondary">
            Neopom = Neo + Pomodoro
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            The Pomodoro technique is a popular time-management tool that
            promotes working in 25-minute highly-focused sessions in order to
            stave off fatigue and maintain concentration.
            <br />
          </Typography>
          {/* <Typography variant="body">
            Do you find yourself easily distracted? Tend to procrastinate? Feel
            overwhelmed with work? Or do you want a boost in productivity? Try
            these steps and repeat!
          </Typography> */}
        </Box>
        <Grid container className={classes.grid} spacing={3}>
          <Grid item xs={12} sm={4}>
            <Box clone mt={2} width="50%" height="50%">
              <ChooseIllustration width="50%" height="50%" />
            </Box>
            <Typography mt={2} variant="subtitle2">
              <br /> Select a task <br />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box clone mt={2} width="50%" height="50%">
              <TimerIllustration width="50%" height="50%" />
            </Box>
            <Typography mt={2} variant="subtitle2">
              <br />
              Focus for 25 minutes <br />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box clone mt={2} width="50%" height="50%">
              <RelaxIllustration width="50%" height="50%" />
            </Box>
            <Typography mt={2} variant="subtitle2">
              <br /> Take a 5 minute break <br />
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="subtitle2" color="textSecondary">
          Take a longer 15-30 minute break after 4 sessions so you can feel
          recharged!
          <br />
          The app allows you to experiment with different time intervals so you
          can find your personal sweet spot for peak productivity.
        </Typography>
      </Box>
      {/* 
      <Box
        // style={{ transform: "translate(0%, 50%)" }}
        className={classes.features}
        width="100%"
        top="100px"
        position="relative"
        // height="100px"
        textAlign="center"
        margin="auto"
      >
        <Grid container display="flex">
          <Grid item xs={12} sm={6} order={sliderOrder}>
            <Box  padding={2} display="flex" justifyContent="center">
              <PhotocardSlider />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} order={descOrder}>
            <Box
              p={2}
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              order={{ xs: 1, sm: 2 }}
            >
              <Typography variant="h5">
                Collect photocards as you complete your work
                <br />
              </Typography>
              <Typography variant="h6" color="textSecondary">
                Receive a random photocard whenever you complete 8 NeoPom
                sessions in one day!
              </Typography>

              <Typography variant="i">
                <br />
                <i>* Must have an account to collect photocards</i> <br />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box> */}

      <Box
        className={classes.features}
        width="100%"
        top="100px"
        position="relative"
        textAlign="center"
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="center"
      >
        <Box
          order={{ xs: 2, sm: 1 }}
          padding={2}
          display="flex"
          width={{ xs: "90%", sm: "40%" }}
          justifyContent="center"
          alignItems="center"
          alignSelf="center"
          flexDirection="column"
        >
          <PhotocardSlider />
          <Typography variant="body" textAlign="center">
            <i> * Must have an account to collect photocards</i>
          </Typography>
        </Box>
        <Box
          p={2}
          item
          order={{ xs: 1, sm: 2 }}
          width={{ xs: "80%", sm: "40%" }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignSelf="center"
        >
          <Typography variant="h5">
            Collect photocards as a reward
            <br />
          </Typography>
          <Typography variant="h6" color="textSecondary">
            You receive random photocards when you reach your personal goals!
          </Typography>
          <Button
            component={Link}
            to="/photocards"
            color="primary"
            size="large"
          >
            See More
          </Button>
        </Box>
      </Box>

      <Box
        className={classes.features}
        width="100%"
        top="150px"
        position="relative"
        textAlign="center"
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="center"
      >
        <Box
          padding={2}
          width={{ xs: "80%", sm: "40%" }}
          justifyContent="center"
          flexDirection="column"
          alignSelf="center"
        >
          <Typography variant="h5">
            Get words of encouragement from NCT
          </Typography>
          <Typography variant="h6" color="textSecondary">
            When you feel down, pick yourself back up with these inspirational
            and wise quotes from NCT members <br />
          </Typography>
          <Button component={Link} to="/quotes" color="primary" size="large">
            See More
          </Button>
        </Box>
        <Box
          p={2}
          width={{ xs: "100%", sm: "40%" }}
          justifyContent="center"
          alignSelf="center"
        >
          <QuoteSlider />
        </Box>
      </Box>

      <Fab
        aria-label="motivation"
        className={classes.fab}
        color="primary"
        size="large"
      >
        <MotivateDialog user={user} />
      </Fab>

      <SignUpDialog
        dialogProps={signUpProps}
        user={user}
        userData={null}
        openSnackbar={() => {}}
      />
    </>
  );
}

HomePage.propTypes = {
  user: PropTypes.object,
};

export default HomePage;
