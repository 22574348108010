import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import * as workerInterval from "worker-interval";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  Box,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import firebase, { firestore } from "../../firebase";
import useFirestoreQuery from "../../utils/useFirestoreQuery";
import SoundControl from "../Sound/SoundControl";
import useWidth from "../../utils/useWidth";
import audioPlayers from "../../assets/sounds";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  startButton: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    height: 70,
    width: 200,
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  divider: {
    margin: "auto",
  },

  grid: {
    marginBottom: theme.spacing(2),
  },
  modal: {
    padding: theme.spacing(2),
  },
  progressBottom: {
    color: theme.palette.grey[400],
  },
  progressTop: {
    position: "absolute",
    left: 0,
  },
  progressContainer: {
    marginBottom: theme.spacing(3),
  },
  content: {
    alignItems: "center",
    alignSelf: "center",
    display: "flex",
    justifyContent: "center",
  },
  circle: {
    strokeLinecap: "round",
  },
  footer: {
    padding: theme.spacing(2),
  },
}));

const emptyData = {
  sessionTotal: 0,
  timer: {
    volume: 1,
    sound: "elevator-ding",
  },
};

const defaultTimerData = {
  focusTime: 25,
  shortBreakTime: 5,
  longBreakTime: 30,
  sessionAudio: "elevator-ding",
  breakAudio: "elevator-ding",
  volume: 100,
  dailyGoal: 8,
  stretchGoal: 12,
};

function TimerDialog(props) {
  // Dialog Properties
  const { userId } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [isCloseRequested, setIsCloseRequested] = useState(false);
  const requestClose = () => setIsCloseRequested(true);

  const { data, status, _error } = useFirestoreQuery(
    firestore.collection("users").doc(userId)
  );
  const timerData = data
    ? { ...defaultTimerData, ...data.timer }
    : defaultTimerData;
  const TODAY = timerData.dayStartTime
    ? moment().subtract(timerData.dayStartTime, "hours").format("YYYY-MM-DD")
    : moment().format("YYYY-MM-DD");
  const dailyData =
    data && data.history && data.history[TODAY]
      ? data.history[TODAY]
      : emptyData;

  // Styling
  let fullScreen;
  let timerSize;
  const width = useWidth();
  switch (width) {
    case "xs":
      fullScreen = true;
      timerSize = 275;
      break;

    case "sm":
      fullScreen = true;
      timerSize = 400;
      break;

    case "md":
    case "lg":
    case "xl":
      fullScreen = false;
      timerSize = 250;
      break;

    default:
      fullScreen = false;
      timerSize = 250;
      break;
  }

  // For break time
  const breakTime = timerData.shortBreakTime * 60;
  const longBreakTime = timerData.longBreakTime * 60;
  const [isOnLongBreak, setIsOnLongBreak] = useState(false);

  // For focused timer
  const focusTime = timerData.focusTime * 60;
  const [isFocused, setIsFocused] = useState(false);
  const [isActive, setIsActive] = useState(false); //isTimerActive
  const [endTime, setEndTime] = useState(null);

  const toggleTimer = () => {
    setIsActive(!isActive);
    setEndTime(
      moment().add(
        isFocused ? focusTime : isOnLongBreak ? longBreakTime : breakTime,
        "seconds"
      )
    );
  };

  const [timer, setTimer] = useState(focusTime);

  const handleBreakTime = () => {
    if (dailyData.sessionTotal > 0 && (dailyData.sessionTotal + 1) % 4 === 0) {
      setTimer(longBreakTime);
      setIsOnLongBreak(true);
    } else {
      setTimer(breakTime);
    }
    if (userId) {
      firestore
        .collection("users")
        .doc(userId)
        .update({
          [`history.${TODAY}.sessionTotal`]: firebase.firestore.FieldValue.increment(
            1
          ),
        });
      if (props.currentTasks && props.currentTasks.length > 0) {
        firestore
          .collection("users")
          .doc(userId)
          .update({
            [`history.${TODAY}.currentTasks`]: firebase.firestore.FieldValue.arrayUnion(
              ...props.currentTasks
            ),
          });
      }
    }
    setIsActive(false);
    setIsFocused(false);
  };

  const handleFocusTime = () => {
    setIsOnLongBreak(false);
    setTimer(focusTime);
    setIsActive(false);
    setIsFocused(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setTimer(focusTime);
    if (!isFocused) setIsFocused(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setIsCloseRequested(false);
      setIsActive(false);
    }, 1000);
  };

  const handleTimeUpdate = () => {
    const expectedTimer = endTime.diff(moment(), "seconds");

    setTimer((timer) => timer - 1);
    if (Math.abs(expectedTimer - timer) > 5) {
      setTimer(expectedTimer);
    }
  };

  useEffect(() => {
    let interval = null;
    if (timer <= 0) {
      if (isFocused) {
        handleBreakTime();
        audioPlayers[timerData.sessionAudio].audio.play();
      } else {
        handleFocusTime();
        audioPlayers[timerData.breakAudio].audio.play();
      }
    } else if (isActive) {
      interval = workerInterval.setInterval(() => {
        // setTimer((timer) => timer - 1);
        handleTimeUpdate();
      }, 1000);
    } else if (!isActive && timer !== 0) {
      workerInterval.clearInterval(interval);
    }
    return () => workerInterval.clearInterval(interval);
  }, [isActive, timer]);

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        className={classes.startButton}
        style={{
          background: `linear-gradient(135deg,${theme.primaryColor.import[300]},${theme.primaryColor.import[800]})`,
          color: theme.palette.primary.contrastText,
        }}
      >
        <h2>Start session</h2>
      </Button>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        className={classes.modal}
        open={open}
        keepMounted
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        fullScreen={fullScreen}
      >
        <DialogTitle>
          <Typography variant="h4">
            {isFocused
              ? "Focus Time"
              : isOnLongBreak
              ? "Long Break Time"
              : "Break Time"}
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={
              isActive && isFocused && !isCloseRequested
                ? requestClose
                : handleClose
            }
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Box
            position="relative"
            display="inline-flex"
            className={classes.progressContainer}
          >
            <CircularProgress
              variant="determinate"
              className={classes.progressBottom}
              size={timerSize}
              thickness={4}
              {...props}
              value={100}
            />
            <CircularProgress
              className={classes.progressTop}
              classes={{
                circle: classes.circle,
              }}
              variant="determinate"
              value={
                (1 -
                  timer /
                    `${
                      isFocused
                        ? focusTime
                        : isOnLongBreak
                        ? longBreakTime
                        : breakTime
                    }`) *
                100
              }
              color={isFocused ? "primary" : "secondary"}
              size={timerSize}
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h3">{`${Math.floor(timer / 60)} : ${(
                "0" + Math.floor(timer % 60)
              ).slice(-2)}`}</Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions className={classes.footer}>
          {userId ? <SoundControl userId={userId} /> : null}
          <Box flex={1} />
          {isCloseRequested ? (
            <>
              <Typography variant="body">
                Are you sure you want to cancel this session?
              </Typography>
              <Button
                variant="contained"
                onClick={() => setIsCloseRequested(false)}
              >
                No
              </Button>
              <Button variant="contained" color="primary" onClick={handleClose}>
                Yes
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                onClick={isFocused ? requestClose : handleFocusTime}
              >
                Cancel {isFocused ? "Session" : "Break"}
              </Button>
              {!isActive && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={toggleTimer}
                >
                  Start
                </Button>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

TimerDialog.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Dialog Properties
  dialogProps: PropTypes.object.isRequired,

  // Custom Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default TimerDialog;
