import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import validate from "validate.js";
import { withRouter, Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Grow from "@material-ui/core/Grow";
import Fade from "@material-ui/core/Fade";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tooltip,
  IconButton,
  Hidden,
  Grid,
  Button,
  Divider,
  TextField,
  Box,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FavoriteIcon from "@material-ui/icons/Favorite";

import AuthProviderList from "../AuthProviderList";

import constraints from "../../data/constraints";
import authentication from "../../services/authentication";
import useWidth from "../../utils/useWidth";

const useStyles = makeStyles((theme) => ({
  closeButton: { color: theme.palette.grey[500] },
  button: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
  },
  modal: { padding: theme.spacing(2) },
  content: {
    alignSelf: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  footer: { padding: theme.spacing(2) },
}));

const messageStyles = makeStyles((theme) => ({
  closeButton: { color: theme.palette.grey[500] },
  button: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  modal: { padding: theme.spacing(2) },
  content: { alignSelf: "center" },
  footer: { padding: theme.spacing(2) },
}));

function ComfortingMessage({ user, width }) {
  const classes = messageStyles();
  const [index, setIndex] = useState(0);

  return (
    <Box flex="display" flexDirection="column" justifyContent="space-evenly">
      {index === 0 && (
        <Fade in={index === 0} {...{ timeout: 2000 }}>
          <Typography variant="h5">
            Breathe. <br />
            It's going to be okay. <br />
            Whatever you're going through, you have the ability to overcome it.
          </Typography>
        </Fade>
      )}

      {index === 1 && (
        <Fade in={index === 1} {...{ timeout: 2000 }}>
          <Typography variant="h5">
            You are so loved. <br />
            You are valuable because you exist. Not because of what you do or
            what you have done, but simply because you are. <br />
            You are worthy now. Not if. Not when. You are worthy of love,
            belonging, forgiveness, and happiness. Right this minute. As is.
          </Typography>
        </Fade>
      )}

      {index === 2 && (
        <Fade in={index === 2} {...{ timeout: 2000 }}>
          <Typography variant="h5">
            You don't need everything figured out right now. <br />
            Let's take this one step at a time.
          </Typography>
        </Fade>
      )}

      {index === 3 && (
        <Fade in={index === 3} {...{ timeout: 2000 }}>
          <Typography variant="h5">
            Want more kind and comforting messages from NCT? <br />
            Or do you want to take that step?
          </Typography>
        </Fade>
      )}

      <Box
        display="flex"
        flexDirection={width == "xs" ? "column" : "row"}
        justifyContent="flex-end"
        alignSelf="stretch"
        mt={4}
      >
        {index === 3 ? (
          <>
            <Button
              component={Link}
              color="primary"
              className={classes.button}
              variant="outlined"
              to={"/quotes/kindness"}
            >
              More kindness
            </Button>
            <Button
              component={Link}
              color="primary"
              className={classes.button}
              variant="contained"
              to={user ? `/user/${user.uid}` : "/user/nctzen"}
            >
              Let's Take that step
            </Button>
          </>
        ) : (
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => setIndex(index + 1)}
          >
            Next
          </Button>
        )}
      </Box>
    </Box>
  );
}

function InspirationalMessage({ user, width }) {
  const classes = messageStyles();
  const [index, setIndex] = useState(0);

  return (
    <Box
      flex="display"
      flexDirection="column"
      justifyContent="space-evenly"
      alignItems="stretch"
    >
      {index === 0 && (
        <Fade in={index === 0} {...{ timeout: 2000 }}>
          <Typography variant="h5">
            That's wonderful! <br />
            I hope you are able to accomplish everything you have set out for
            yourself.
            <br />
          </Typography>
        </Fade>
      )}

      {index === 1 && (
        <Fade in={index === 1} {...{ timeout: 2000 }}>
          <>
            <Typography variant="h5">
              "The future belongs to those who believe in the beauty of their
              dreams"
            </Typography>
            <Box width="100%" mt={1}>
              <Typography variant="h6">
                <i>Eleanor Roosevelt</i>
              </Typography>
            </Box>
          </>
        </Fade>
      )}

      {index === 2 && (
        <Fade in={index === 2} {...{ timeout: 2000 }}>
          <Typography variant="h5">
            Want to channel your positive emotions into productive energy?
          </Typography>
        </Fade>
      )}

      <Box
        display="flex"
        flexDirection={width == "xs" ? "column" : "row"}
        justifyContent="flex-end"
        alignSelf="stretch"
        mt={4}
      >
        {index === 2 ? (
          <Button
            component={Link}
            color="primary"
            className={classes.button}
            variant="contained"
            to={user ? `/user/${user.uid}` : "/user/nctzen"}
          >
            Let's go!
          </Button>
        ) : (
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => setIndex(index + 1)}
          >
            Next
          </Button>
        )}
      </Box>
    </Box>
  );
}

function CheerfulMessage({ user, width }) {
  const classes = messageStyles();
  const [index, setIndex] = useState(0);

  return (
    <>
      {index === 0 && (
        <Fade in={index === 0} {...{ timeout: 2000 }}>
          <Typography variant="h5">
            You got this! <br />
            You’re braver than you believe, stronger than you seem, and smarter
            than you think.
            <br />
            You have everything you need to accomplish your goals.
          </Typography>
        </Fade>
      )}

      {index === 1 && (
        <Fade in={index === 1} {...{ timeout: 2000 }}>
          <Typography variant="h5" as="i">
            “Sometimes the smallest step in the right direction ends up being
            the biggest step of your life. Tip toe if you must, but take the
            step.”
          </Typography>
        </Fade>
      )}

      {index === 2 && (
        <Fade in={index === 2} {...{ timeout: 2000 }}>
          <Typography variant="h5">
            Want to get more motivational words from NCT? Or push through and
            start being productive?
          </Typography>
        </Fade>
      )}

      <Box
        display="flex"
        flexDirection={width == "xs" ? "column" : "row"}
        justifyContent="flex-end"
        alignSelf="stretch"
        mt={4}
      >
        {index === 2 ? (
          <>
            <Button
              component={Link}
              color="primary"
              className={classes.button}
              variant="outlined"
              to={"/quotes"}
            >
              get motivation
            </Button>
            <Button
              component={Link}
              color="primary"
              className={classes.button}
              variant="contained"
              to={user ? `/user/${user.uid}` : "/user/nctzen"}
            >
              Let's push through
            </Button>
          </>
        ) : (
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => setIndex(index + 1)}
          >
            Next
          </Button>
        )}
      </Box>
    </>
  );
}

function MotivateDialog({ user }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(undefined);

  let fullScreen;
  const width = useWidth();
  switch (width) {
    case "xs":
      fullScreen = true;
      break;
    case "sm":
    case "md":
    case "lg":
    case "xl":
      fullScreen = false;
      break;

    default:
      fullScreen = false;
      break;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setMessage(undefined);
    }, 1000);
  };

  return (
    <>
      <FavoriteIcon onClick={() => setOpen(true)} />

      {/* <Dialog
        fullWidth
        maxWidth="sm"
        disableBackdropClick={performingAction}
        disableEscapeKeyDown={performingAction}
        {...dialogProps}
        onKeyPress={this.handleKeyPress}
        onExited={this.handleExited}
      > */}
      <Dialog
        className={classes.modal}
        open={open}
        keepMounted
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Box
            display="flex"
            flexDirection="row"
            justify="center"
            alignItems="center"
          >
            <Box flexGrow={1}>
              <Typography variant="h6">
                {message ? null : `How are you feeling?`}
              </Typography>
            </Box>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.content}>
          {message ? (
            <>
              {message === "comforting" && (
                <ComfortingMessage user={user} width={width} />
              )}
              {message === "cheerful" && (
                <CheerfulMessage user={user} width={width} />
              )}
              {message === "inspirational" && (
                <InspirationalMessage user={user} width={width} />
              )}
            </>
          ) : (
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => setMessage("inspirational")}
              >
                I'm doing great
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => setMessage("cheerful")}
              >
                I'm okay
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => setMessage("comforting")}
              >
                I'm not doing so well
              </Button>
            </Box>
          )}
        </DialogContent>
        <DialogActions className={classes.footer}></DialogActions>
      </Dialog>
    </>
  );
}

MotivateDialog.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Dialog Properties
  dialogProps: PropTypes.object.isRequired,

  // Custom Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default MotivateDialog;
